import { Center } from "@mantine/core";
import { usePageViewTracking } from "hooks/analytics";
import React, { useState } from "react";
import { CustomerCredential } from "types";
import { LoginAuthCodeInput, LoginForm } from "./components";

export const LoginPage: React.FC = () => {
  usePageViewTracking("login");
  const [customerCredential, setCustomerCredential] = useState<CustomerCredential>();
  const [attemptsLeft, setAttemptsLeft] = useState<number>();

  return (
    <Center h="100%" px="md">
      {customerCredential && attemptsLeft !== undefined ? (
        <LoginAuthCodeInput
          customerCredential={customerCredential}
          onCancel={() => setCustomerCredential(undefined)}
          attemptsLeft={attemptsLeft}
          setAttemptsLeft={setAttemptsLeft}
        />
      ) : (
        <LoginForm
          setCustomerCredential={setCustomerCredential}
          setAttemptsLeft={setAttemptsLeft}
        />
      )}
    </Center>
  );
};
