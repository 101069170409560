import { Anchor, Group, Stack, Text, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { invalidateClaimsQuery } from "helpers/claim";
import { useRetailerQuery } from "hooks";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createClaim as _createClaim } from "services/api/claim";
import { CoverageType, IncidentType, RentalItem } from "types";
import { useStyles } from "./ClaimCreation.styles";
import { ClaimCreationSuccess } from "./ClaimCreationSuccess";
import { ClaimCreationForm } from "./Form";
import { ClaimIncidentTypeSelect } from "./IncidentTypeSelect";

interface Props {
  rentalItem: RentalItem;
  retailerId: string;
  close: () => void;
  onClaimCreated: () => void;
}

const INCIDENT_TYPE_BY_COVERAGE_TYPE = {
  [CoverageType.BREAKAGE]: IncidentType.DAMAGE,
  [CoverageType.BREAKDOWN]: IncidentType.BREAKDOWN,
  [CoverageType.LOSS]: IncidentType.OTHER,
  [CoverageType.THEFT]: IncidentType.THEFT,
};

export const ClaimCreation: React.FC<Props> = ({
  rentalItem,
  retailerId,
  close,
  onClaimCreated,
}) => {
  const { t } = useTranslation();
  const [selectedIncidentType, setSelectedIncidentType] = useState<IncidentType>();
  const { retailer } = useRetailerQuery(retailerId);
  const { classes } = useStyles();

  const incidentTypes = useMemo(() => {
    if (!retailer) return undefined;

    const coverageConfiguration = retailer.coverage_configurations.find(
      ({ code }) => code === rentalItem.coverage_configuration?.code
    );
    if (!coverageConfiguration) return [];

    return coverageConfiguration.coverages.map(
      (coverage) => INCIDENT_TYPE_BY_COVERAGE_TYPE[coverage.coverage_type]
    );
  }, [rentalItem.coverage_configuration?.code, retailer]);

  const {
    mutate: createClaim,
    isLoading: isCreatingClaim,
    isSuccess: isClaimCreated,
  } = useMutation({
    mutationFn: _createClaim,
    onSuccess: () => {
      invalidateClaimsQuery();
      onClaimCreated();
    },
  });

  if (!incidentTypes) return null;

  if (isClaimCreated) return <ClaimCreationSuccess close={close} />;

  return (
    <Stack h="100%" spacing="lg" className={classes.container}>
      <Anchor
        component="div"
        onClick={() => (selectedIncidentType ? setSelectedIncidentType(undefined) : close())}
      >
        <Group spacing="xs">
          <IconArrowLeft />
          <Text weight={700}>
            {selectedIncidentType ? t("claim.backToPreviousStep") : t("claim.backToRental")}
          </Text>
        </Group>
      </Anchor>

      <Stack className={classes.container}>
        <Title
          size="h4"
          className={classes.title}
          data-incident-type-selected={!!selectedIncidentType}
        >
          {t("claim.reportAClaim")}
        </Title>

        {selectedIncidentType ? (
          <ClaimCreationForm
            rentalItemId={rentalItem.id}
            incidentType={selectedIncidentType}
            createClaim={createClaim}
            isCreatingClaim={isCreatingClaim}
          />
        ) : (
          <ClaimIncidentTypeSelect
            incidentTypes={incidentTypes}
            setSelectedIncidentType={setSelectedIncidentType}
          />
        )}
      </Stack>
    </Stack>
  );
};
