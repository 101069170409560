import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan, largerThan } }) => ({
  container: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      cursor: "pointer",
    },
  },

  editButton: {
    alignSelf: "flex-start",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  chevronIcon: {
    alignSelf: "center",

    [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },
}));
