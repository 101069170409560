import { Anchor, Center, Flex, Group, Tabs, Text, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { RentalStatusBadge } from "components";
import { useRental } from "hooks";
import { useEventTracking, usePageViewTracking } from "hooks/analytics";
import pick from "lodash/pick";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { InvoicePaymentStatus, RoutePath } from "types";
import { useStyles } from "./RentalPage.styles";
import { RentalBilling, RentalDetails } from "./components";

export const RentalPage: React.FC = () => {
  usePageViewTracking("rental-details");
  const { t } = useTranslation();
  const { rentalId } = useParams<"rentalId">();
  const { trackClick } = useEventTracking();

  const rental = useRental(String(rentalId));

  const hasUnpaidInvoices = useMemo(
    () =>
      !!rental?.invoices?.some((invoice) => invoice.payment_status === InvoicePaymentStatus.FAILED),
    [rental?.invoices]
  );
  const { classes } = useStyles({ hasUnpaidInvoices });

  if (rental === null) return null;

  if (rental === undefined)
    return (
      <Center>
        <Text weight={600}>{t("rental.rentalNotFound")}</Text>
      </Center>
    );

  return (
    <div>
      <Anchor component={Link} to={RoutePath.RENTALS} className={classes.backToRentalsLink}>
        <Group spacing="xs">
          <IconArrowLeft />
          <Text weight={700} span>
            {t("rental.backToRentals")}
          </Text>
        </Group>
      </Anchor>

      <Flex className={classes.rentalHeader}>
        <Title size="h4">{t("rental.rentalLabel", { reference: rental.reference })}</Title>

        <RentalStatusBadge status={rental.status} />
      </Flex>

      <Tabs
        defaultValue={hasUnpaidInvoices ? "billing" : "details"}
        classNames={pick(classes, "panel", "tabsList")}
      >
        <Tabs.List>
          <Tabs.Tab
            value="details"
            onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_tab_rental_details")}
          >
            {t("rental.details")}
          </Tabs.Tab>
          <Tabs.Tab
            value="billing"
            onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_tab_rental_billing")}
          >
            {t("rental.billing")}
          </Tabs.Tab>
        </Tabs.List>

        <Flex className={classes.panelWrapper}>
          <Tabs.Panel value="details">
            <RentalDetails rental={rental} />
          </Tabs.Panel>

          <Tabs.Panel value="billing">
            <RentalBilling rental={rental} />
          </Tabs.Panel>
        </Flex>
      </Tabs>
    </div>
  );
};
