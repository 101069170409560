import { Group, Stack, Text } from "@mantine/core";
import { createPrice, formatPrice, sumPrices } from "helpers/price";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Rental } from "types";
import { RentalItemCard } from "./ItemCard";

interface Props {
  rental: Rental;
}

export const RentalDetails: React.FC<Props> = ({ rental: { rental_items, retailer_infos } }) => {
  const { t } = useTranslation();

  const otherInstalmentWithTaxTotalPrice = useMemo(
    () => sumPrices(rental_items.map((item) => createPrice(item.other_instalment_with_tax))),
    [rental_items]
  );

  return (
    <Stack spacing="sm">
      <Group position="apart">
        <Text weight={700}>{t("rental.rentedItemsCount", { count: rental_items.length })}</Text>

        <Text weight={700}>
          {t("rental.priceByMonth", {
            price: formatPrice(otherInstalmentWithTaxTotalPrice),
          })}
        </Text>
      </Group>

      {rental_items.map((rentalItem) => (
        <RentalItemCard
          key={rentalItem.id}
          rentalItem={rentalItem}
          retailerId={retailer_infos.id}
        />
      ))}
    </Stack>
  );
};
