import { RentalItem } from "types";
import { createContext } from "../createContext";

export type AppContextValue = {
  openedHelpRequest: boolean;
  openHelpRequest: () => void;
  closeHelpRequest: (action?: () => void) => void;

  openedClaimCreation: boolean;
  closeClaimCreation: (action?: () => void) => void;
  openClaimCreation: (rentalItem: RentalItem, retailerId: string) => void;
  rentalItemClaimCreation?: RentalItem;
  retailerIdClaimCreation?: string;
  onClaimCreated: () => void;
};

export const [useAppContext, AppContextProvider] = createContext<AppContextValue>();
