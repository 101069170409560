import { createStyles } from "@mantine/core";
import { DARK_BLUE, MEDIUM_SCREEN_BREAKPOINT, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(
  ({ white, spacing, fontSizes, fn: { smallerThan, largerThan } }) => ({
    header: {
      height: "100%",
      backgroundColor: DARK_BLUE,
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        paddingLeft: spacing.sm,
        paddingRight: spacing.sm,
      },
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },
    },
    logo: {
      fill: white,
      width: "auto",
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        height: 24,
      },
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        height: 32,
      },
    },
    contactUs: {
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },
    logout: {
      fontWeight: 700,
      color: white,
      fontSize: fontSizes.md,
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        fontSize: fontSizes.sm,
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        display: "none",
      },

      svg: {
        width: "1.5em",
        height: "1.5em",
      },
    },
  })
);
