import { CoverageOwner, CoverageType, FinancingMode } from "types";
import { z } from "zod";
import { enumFrom } from "./utils";

export const retailerCoverageSchema = z.object({
  coverage_type: enumFrom(CoverageType),
  finance_owner: enumFrom(CoverageOwner),
  operation_owner: enumFrom(CoverageOwner),
});

export const retailerCoverageConfigurationSchema = z.object({
  code: z.string(),
  coverages: z.array(retailerCoverageSchema),
});

export const retailerDurationConfigurationSchema = z.object({
  duration: z.number(),
  label: z.string(),
});

export const retailerScoringConfigurationSchema = z.object({
  financing_mode: enumFrom(FinancingMode),
});

export const retailerStyleSchema = z.object({
  logo_url: z.string(),
});

export const retailerUrlsConfigSchema = z.object({
  rental_conditions_url: z.string().nullable(),
  claim_form_url: z.string().nullable(),
});

export const retailerSchema = z.object({
  id: z.string(),
  name: z.string(),
  duration_configurations: z.array(retailerDurationConfigurationSchema),
  coverage_configurations: z.array(retailerCoverageConfigurationSchema),
  scoring_configuration: retailerScoringConfigurationSchema,
  urls_config: retailerUrlsConfigSchema,
  style: retailerStyleSchema,
  activate_claims: z.boolean(),
});
