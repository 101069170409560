import { useQueries } from "@tanstack/react-query";
import { getReturn } from "services/api/return";

export const useReturnQueries = (returnIds: string[]) => {
  const queries = useQueries({
    queries: returnIds.map((returnId) => ({
      queryKey: ["return", returnId],
      queryFn: () => getReturn(returnId),
      retry: false,
      retryOnMount: false,
      staleTime: Infinity,
    })),
  });

  return queries.map(({ data, ...queryResult }) => ({ return: data, ...queryResult }));
};
