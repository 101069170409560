import { Avatar, Stack, Text } from "@mantine/core";
import { removeAccents } from "helpers/string";
import React, { useMemo } from "react";
import { Customer } from "types";
import { ProfileCustomerBillingAddress } from "./BillingAddress";
import { ProfileCustomerEmail } from "./Email";
import { ProfileCustomerPhone } from "./Phone";
import { ProfileCustomerBirth } from "./ProfileCustomerBirth";
import { ProfileCustomerCompanyContact } from "./ProfileCustomerCompanyContact";
import { ProfileCustomerCompanyDetails } from "./ProfileCustomerCompanyDetails";
import { useStyles } from "./ProfileCustomerDetails.styles";

interface Props {
  customer: Customer;
}

export const ProfileCustomerDetails: React.FC<Props> = ({ customer }) => {
  const { classes } = useStyles();

  const customerAvatarLabel = useMemo(
    () =>
      customer.is_company && !!customer.company_name
        ? removeAccents(customer.company_name).toUpperCase()[0]
        : removeAccents(customer.first_name).toUpperCase()[0] +
          removeAccents(customer.last_name).toUpperCase()[0],
    [customer.company_name, customer.first_name, customer.is_company, customer.last_name]
  );

  const customerLabel = useMemo(
    () =>
      customer.is_company && !!customer.company_name
        ? customer.company_name
        : `${customer.first_name} ${customer.last_name}`,
    [customer.company_name, customer.first_name, customer.is_company, customer.last_name]
  );

  return (
    <Stack spacing="sm">
      <Avatar radius={40} size={80} className={classes.customerAvatar}>
        {customerAvatarLabel}
      </Avatar>

      <Text fw={700} className={classes.customerLabel}>
        {customerLabel}
      </Text>

      <Stack spacing="sm" className={classes.sectionsWrapper}>
        <ProfileCustomerCompanyDetails customer={customer} />
        <ProfileCustomerCompanyContact customer={customer} />
        <ProfileCustomerPhone customer={customer} />
        <ProfileCustomerEmail customer={customer} />
        <ProfileCustomerBirth customer={customer} />
        <ProfileCustomerBillingAddress customer={customer} />
      </Stack>
    </Stack>
  );
};
