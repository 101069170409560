import {
  InvoicePaymentMethod,
  InvoicePaymentMethodBrand,
  InvoicePaymentStatus,
  InvoiceType,
} from "types";
import { z } from "zod";
import { enumFrom, nullableDefault } from "./utils";

export const invoicePaymentInfoSchema = z.object({
  payment_method_id: z.string(),
  payment_method: enumFrom(InvoicePaymentMethod),
  payment_method_last4: z.string(),
  payment_method_brand: enumFrom(InvoicePaymentMethodBrand).nullable(),
});

export const invoiceSchema = z.object({
  id: z.string(),
  total_amount_cents: z.number(),
  last_payment_retry: z.string().nullable(),
  issuing_date: z.string(),
  updated_at: z.string(),
  file_url: z.string().nullable(),
  payment_status: enumFrom(InvoicePaymentStatus),
  payment_info: invoicePaymentInfoSchema.nullable(),
  number: z.string(),
  sline_invoice_type: nullableDefault(enumFrom(InvoiceType), InvoiceType.FIRST_INVOICE),
  session_id: z.string().nullable(),
});
