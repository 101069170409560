import { CustomerCredential, CustomerCredentialType } from "types";

export const getCustomerCredentialValue = (
  customerCredential: CustomerCredential
): Record<CustomerCredentialType, string | undefined> & { type: CustomerCredentialType } => {
  if ("email" in customerCredential)
    return {
      email: customerCredential.email,
      phone: undefined,
      type: CustomerCredentialType.EMAIL,
    };

  if ("phone" in customerCredential)
    return {
      phone: customerCredential.phone,
      email: undefined,
      type: CustomerCredentialType.PHONE,
    };

  throw Error("User auth credential must contain either an email or a phone property.");
};
