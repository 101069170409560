import { Badge, BadgeState } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { RentalStatus } from "types";

interface Props {
  status: RentalStatus;
}

const BADE_STATE_BY_STATUS: Record<RentalStatus, BadgeState> = {
  [RentalStatus.CREATED]: "message",
  [RentalStatus.PENDING_DOCUMENTS]: "warning",
  [RentalStatus.PENDING_E_SIGNATURE]: "warning",
  [RentalStatus.PENDING_REVIEW]: "message",
  [RentalStatus.REVIEWED]: "message",
  [RentalStatus.CANCELED]: "negative",
  [RentalStatus.REJECTED]: "negative",
  [RentalStatus.ACTIVE]: "positive",
  [RentalStatus.DEBT_RECOVERY]: "negative",
  [RentalStatus.CLOSED]: "message",
};

export const RentalStatusBadge: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  return <Badge state={BADE_STATE_BY_STATUS[status]}>{t(`rental.rentalStatuses.${status}`)}</Badge>;
};
