import { CSSObject, MantineTheme } from "@mantine/core";
import { BLUE, mantineTheme } from "theme";

export const useStyles = () => ({
  styles: ({ white, fn: { rgba }, shadows }: MantineTheme) =>
    ({
      ":root": {
        "--pka-font-family": mantineTheme.fontFamily,
      },

      ".pka-panel": {
        boxShadow: shadows.sm,
      },

      ".pka-panel-suggestions": {
        maxHeight: "7.5rem",
      },

      ".pka-panel-suggestion.pka-active": {
        backgroundColor: BLUE,
      },

      "button.pka-panel-suggestion-action": {
        backgroundColor: rgba(white, 0.6),
      },
    } as CSSObject),
});
