import { Group, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil } from "@tabler/icons-react";
import { Button } from "components";
import { useAppContext } from "contexts/AppContext";
import { getRentalPaymentInfo } from "helpers/rental";
import { useCustomerQuery, useRetailerQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as PaymentSketch } from "resources/sketches/payment.svg";
import { TrackingEventCategory } from "services/analytics";
import { FinancingMode, InvoicePaymentMethod, Rental } from "types";
import { RentalPaymentMethodUpdate } from "../../PaymentMethodUpdate";
import { useStyles } from "./RentalPaymentMethodUpdateButton.styles";

interface Props {
  rental: Rental;
}

export const RentalPaymentMethodUpdateButton: React.FC<Props> = ({ rental }) => {
  const { t } = useTranslation();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const { customer } = useCustomerQuery();
  const { retailer } = useRetailerQuery(rental.retailer_infos.id);
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();
  const { openHelpRequest } = useAppContext();

  const canUpdatePaymentMethod = useMemo(() => {
    if (!retailer) return undefined;

    return (
      getRentalPaymentInfo(rental)?.payment_method !== InvoicePaymentMethod.SEPA_DEBIT ||
      retailer.scoring_configuration.financing_mode !== FinancingMode.CACF
    );
  }, [rental, retailer]);

  if (!customer || canUpdatePaymentMethod === undefined) return null;

  return (
    <>
      <Button
        variant="ghost"
        onClick={() => {
          openModal();
          trackClick(TrackingEventCategory.BUTTON, "click_edit_payment_info");
        }}
        leftIcon={<IconPencil />}
      >
        {t("rental.edit")}
      </Button>

      <Modal
        opened={modalOpened}
        onClose={closeModal}
        withCloseButton
        title={canUpdatePaymentMethod ? t("rental.editPaymentMethod") : t("rental.updateIban")}
      >
        {canUpdatePaymentMethod ? (
          <Stack className={classes.container}>
            <Group>
              <PaymentSketch className={classes.paymentSketch} />
              <Text>{t("rental.editPaymentMethodDescription")}</Text>
            </Group>

            <RentalPaymentMethodUpdate
              rental={rental}
              customer={customer}
              onSuccess={closeModal}
              onCancel={closeModal}
            />
          </Stack>
        ) : (
          <Stack className={classes.container} justify="space-between">
            <Text>
              <Trans i18nKey="rental.updateIbanDescription" />
            </Text>

            <Group spacing="xs" className={classes.buttonsWrapper}>
              <Button variant="ghost" onClick={closeModal}>
                {t("rental.quit")}
              </Button>

              <Button
                type="submit"
                onClick={() => {
                  closeModal();
                  openHelpRequest();
                }}
              >
                {t("rental.contactSupport")}
              </Button>
            </Group>
          </Stack>
        )}
      </Modal>
    </>
  );
};
