import { Anchor, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import { BadgeIcon } from "components";
import { createDate, formatDate } from "helpers/date";
import { createPrice, formatPrice } from "helpers/price";
import React from "react";
import { useTranslation } from "react-i18next";
import { Rental } from "types";
import { useStyles } from "./RentalCreditNotesList.styles";

interface Props {
  rental: Rental;
}

export const RentalCreditNotesList: React.FC<Props> = ({ rental: { credit_notes } }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (credit_notes.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Title size="h6" className={classes.title}>
        {t("rental.creditNotes")}
      </Title>

      <Paper withBorder p="sm" shadow="xs" radius="md">
        <Stack spacing="sm">
          {credit_notes.map((creditNote) => (
            <Group position="apart" align="start" key={creditNote.id}>
              <Anchor
                href={creditNote.file_url ?? undefined}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.creditNoteFileLink}
              >
                <Group spacing="xs">
                  {!!creditNote.file_url && (
                    <BadgeIcon icon={IconArrowDown} state="message" bg="transparent" withBorder />
                  )}

                  <Text span>{formatDate(createDate(creditNote.issuing_date))}</Text>
                </Group>
              </Anchor>

              <Text>{formatPrice(createPrice(creditNote.total_amount_cents))}</Text>
            </Group>
          ))}
        </Stack>
      </Paper>
    </Stack>
  );
};
