import { Anchor, Group, Stack, Text, Title, UnstyledButton } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconArrowLeft, IconChevronRight, IconLogout } from "@tabler/icons-react";
import { Button } from "components";
import { useAuthContext } from "contexts/AuthContext";
import { useCustomerQuery } from "hooks";
import { useEventTracking, usePageViewTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { BLUE, GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";
import { ProfileCustomerDetails } from "./CustomerDetails";
import { useStyles } from "./ProfilePage.styles";

export const ProfilePage: React.FC = () => {
  usePageViewTracking("profile");
  const { logOut } = useAuthContext();
  const { trackClick } = useEventTracking();
  const { t } = useTranslation();
  const { customer } = useCustomerQuery();
  const { classes } = useStyles();
  const [customerDetailsOpened, { open: openCustomerDetails, close: closeCustomerDetails }] =
    useDisclosure();

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT - 1}px)`);

  if (!customer) return null;

  if (isSmallScreen && customerDetailsOpened)
    return (
      <Stack spacing="lg">
        <Anchor>
          <Group spacing="xs" onClick={closeCustomerDetails}>
            <IconArrowLeft />
            <Text weight={700} span>
              {t("profile.backToProfile")}
            </Text>
          </Group>
        </Anchor>

        <Title size="h3" className={classes.title}>
          {t("profile.personalInfo")}
        </Title>

        <ProfileCustomerDetails customer={customer} />
      </Stack>
    );

  if (isSmallScreen)
    return (
      <Stack spacing="xl" className={classes.container}>
        <Stack spacing="lg">
          <Title size="h3" className={classes.title}>
            {t("profile.myProfile")}
          </Title>

          <UnstyledButton
            className={classes.openCustomerDetailsButton}
            onClick={openCustomerDetails}
          >
            <Group position="apart">
              <Text fw={700}>{t("profile.personalInfo")}</Text>

              <IconChevronRight color={GRAY} />
            </Group>
          </UnstyledButton>

          <Stack spacing="xs">
            <Text fw={700}>{t("profile.helpfulLinks")}</Text>

            <Anchor
              weight={700}
              href="https://www.sline.io/conditions-generales-dutilisation"
              target="_blank"
              rel="noopener noreferrer"
              color={BLUE}
              td="underline"
              size="sm"
            >
              {t("profile.generalConditionsOfUse")}
            </Anchor>

            <Anchor
              weight={700}
              href="https://www.sline.io/politique-de-confidentialite"
              target="_blank"
              rel="noopener noreferrer"
              color={BLUE}
              td="underline"
              size="sm"
            >
              {t("profile.privacyPolicy")}
            </Anchor>
          </Stack>
        </Stack>

        <Button
          rightIcon={<IconLogout />}
          onClick={() => {
            trackClick(TrackingEventCategory.LINK, "click_log_out");
            logOut();
          }}
        >
          {t("profile.logOut")}
        </Button>
      </Stack>
    );

  return (
    <Stack spacing="lg">
      <Title size="h3" className={classes.title}>
        {t("profile.myProfile")}
      </Title>

      <ProfileCustomerDetails customer={customer} />
    </Stack>
  );
};
