import { Group, Stack, Text } from "@mantine/core";
import { createPrice, formatPrice, sumPrices } from "helpers/price";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Session } from "types";
import { OrderItemCard } from "./ItemCard";
import { OrderItem } from "./helpers";

interface Props {
  session: Session;
  orderItems: OrderItem[];
}

export const OrderPurchasedItemsList: React.FC<Props> = ({ session, orderItems }) => {
  const { t } = useTranslation();

  const purchasedItems = useMemo(
    () => orderItems.filter((orderItem) => !orderItem.rent),
    [orderItems]
  );

  const firstInstalmentWithTaxTotalPrice = useMemo(
    () => sumPrices(purchasedItems.map((item) => createPrice(item.first_instalment_with_tax))),
    [purchasedItems]
  );

  if (purchasedItems.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Group position="apart">
        <Text weight={700}>{t("order.purchasedItemsCount", { count: purchasedItems.length })}</Text>

        <Text weight={700}>{formatPrice(firstInstalmentWithTaxTotalPrice)}</Text>
      </Group>

      {purchasedItems.map((purchasedItem) => (
        <OrderItemCard
          key={purchasedItem.id}
          orderItem={purchasedItem}
          retailerId={session.retailer_infos.id}
        />
      ))}
    </Stack>
  );
};
