import { Anchor, Group, Image, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Badge, Button, ItemDetailsModal } from "components";
import { createDate, formatDate } from "helpers/date";
import { getItemInsuredCoverageTypes } from "helpers/item";
import { createPrice, formatPrice } from "helpers/price";
import { useRetailerQuery, useSession } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { RentalItem } from "types";
import { RentalItemClaimButton } from "./ClaimButton";
import { useStyles } from "./RentalItemCard.styles";

interface Props {
  rentalItem: RentalItem;
  retailerId: string;
}

export const RentalItemCard: React.FC<Props> = ({ rentalItem, retailerId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { trackClick } = useEventTracking();
  const [detailsModalOpened, { open: openDetailsModal, close: closeDetailsModal }] =
    useDisclosure();
  const session = useSession(rentalItem.session_id);
  const { retailer } = useRetailerQuery(retailerId);

  const insuredCoverageTypes = useMemo(() => {
    if (!retailer || !rentalItem.coverage_configuration) return [];

    return getItemInsuredCoverageTypes(
      rentalItem.coverage_configuration.code,
      retailer.coverage_configurations
    );
  }, [rentalItem.coverage_configuration, retailer]);

  return (
    <Paper withBorder shadow="xs" p="sm" radius="md">
      <Group align="center">
        <Image
          src={rentalItem.image}
          height={100}
          width={100}
          fit="cover"
          radius="sm"
          className={classes.image}
        />

        <Stack spacing="xs" justify="space-between" className={classes.rightSection}>
          {rentalItem.rental_start_date ? (
            <Badge state="message" className={classes.smallScreenShippedBadge}>
              {t("rental.shippedItemDate", {
                rentalStartDate: formatDate(createDate(rentalItem.rental_start_date)),
              })}
            </Badge>
          ) : (
            <Badge state="negative" className={classes.smallScreenShippedBadge}>
              {t("rental.unshippedItem")}
            </Badge>
          )}

          <Text className={classes.smallScreenPrice}>
            {t("rental.priceByMonth", {
              price: formatPrice(createPrice(rentalItem.other_instalment_with_tax)),
            })}
          </Text>

          <Group position="apart">
            <Text weight={700}>{rentalItem.name}</Text>

            {rentalItem.rental_start_date ? (
              <Badge state="message" className={classes.shippedBadge}>
                {t("rental.shippedItem")}
              </Badge>
            ) : (
              <Badge state="negative" className={classes.shippedBadge}>
                {t("rental.unshippedItem")}
              </Badge>
            )}
          </Group>

          {!!session && (
            <Anchor component={Link} to={`/orders/${session.id}`} td="underline">
              <Trans
                i18nKey="rental.rentalItemOrderLabel"
                values={{ sessionReference: session.reference }}
              />
            </Anchor>
          )}

          <Text className={classes.price}>
            {t("rental.priceByMonth", {
              price: formatPrice(createPrice(rentalItem.other_instalment_with_tax)),
            })}
          </Text>

          {!!rentalItem.rental_start_date && !!rentalItem.rental_end_date && (
            <Text>
              {t("rental.fromDateToDatePart1")}{" "}
              <Text span weight={700}>
                {formatDate(createDate(rentalItem.rental_start_date))}
              </Text>{" "}
              {t("rental.fromDateToDatePart2")}{" "}
              <Text span weight={700}>
                {formatDate(createDate(rentalItem.rental_end_date))}
              </Text>
            </Text>
          )}

          <Group position="right" spacing="xs">
            <RentalItemClaimButton rentalItem={rentalItem} retailerId={retailerId} />

            <Button
              variant="secondary"
              onClick={() => {
                openDetailsModal();
                trackClick(TrackingEventCategory.BUTTON, "click_modal_product_detail");
              }}
            >
              {t("rental.showDetail")}
            </Button>

            <ItemDetailsModal
              opened={detailsModalOpened}
              close={closeDetailsModal}
              item={rentalItem}
              insuredCoverageTypes={insuredCoverageTypes}
            />
          </Group>
        </Stack>
      </Group>
    </Paper>
  );
};
