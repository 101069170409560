import { Group, Image, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Button, ItemDetailsModal } from "components";
import { getItemInsuredCoverageTypes } from "helpers/item";
import { createPrice, formatPrice } from "helpers/price";
import { useRetailerQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { OrderItem } from "../helpers";
import { useStyles } from "./OrderItemCard.styles";

interface Props {
  orderItem: OrderItem;
  retailerId: string;
}

export const OrderItemCard: React.FC<Props> = ({ orderItem, retailerId }) => {
  const { t } = useTranslation();
  const [detailsModalOpened, { open: openDetailsModal, close: closeDetailsModal }] =
    useDisclosure();
  const { classes } = useStyles();
  const { retailer } = useRetailerQuery(retailerId);
  const { trackClick } = useEventTracking();

  const insuredCoverageTypes = useMemo(() => {
    if (!retailer || !orderItem.coverage_configuration_code) return [];

    return getItemInsuredCoverageTypes(
      orderItem.coverage_configuration_code,
      retailer.coverage_configurations
    );
  }, [orderItem.coverage_configuration_code, retailer]);

  const formattedPrice = useMemo(
    () =>
      orderItem.rent
        ? t("rental.priceByMonth", {
            price: formatPrice(createPrice(orderItem.other_instalment_with_tax)),
          })
        : formatPrice(createPrice(orderItem.first_instalment_with_tax)),
    [orderItem.first_instalment_with_tax, orderItem.other_instalment_with_tax, orderItem.rent, t]
  );

  return (
    <Paper withBorder shadow="xs" p="sm" radius="md">
      <Group align="center">
        <Image
          src={orderItem.image}
          height={100}
          width={100}
          fit="cover"
          radius="sm"
          className={classes.image}
        />

        <Stack spacing="xs" justify="space-between" className={classes.rightSection}>
          <Text className={classes.smallScreenPrice}>{formattedPrice}</Text>

          <Text weight={700}>{orderItem.name}</Text>

          <Text className={classes.price}>{formattedPrice}</Text>

          {insuredCoverageTypes.length > 0 && (
            <Text>
              {t("order.insuredCoverageTypes", {
                coverageTypes: insuredCoverageTypes.map((coverageType) =>
                  t(`order.coverageTypes.${coverageType}`)
                ),
              })}
            </Text>
          )}

          <Button
            variant="secondary"
            onClick={() => {
              openDetailsModal();
              trackClick(TrackingEventCategory.BUTTON, "click_modal_product_detail");
            }}
            className={classes.detailsButton}
          >
            {t("rental.showDetail")}
          </Button>

          <ItemDetailsModal
            opened={detailsModalOpened}
            close={closeDetailsModal}
            item={orderItem}
            insuredCoverageTypes={insuredCoverageTypes}
          />
        </Stack>
      </Group>
    </Paper>
  );
};
