import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  image: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  rightSection: {
    flex: 1,
  },

  smallScreenShippedBadge: {
    display: "none",
    alignSelf: "flex-start",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "block",
    },
  },

  smallScreenPrice: {
    display: "none",
    alignSelf: "flex-end",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "block",
    },
  },

  price: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  shippedBadge: {
    flexShrink: 0,
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },
}));
