import { useClaimsQuery, useRentalsQuery } from "hooks";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TrackingEventData } from "services/analytics";

export const useTrackingEventData = (): TrackingEventData => {
  const { rentals } = useRentalsQuery();
  const { claims } = useClaimsQuery();

  const { rentalId } = useParams<"rentalId">();
  const currentRentals = useMemo(() => {
    if (rentalId) return rentals?.filter((rental) => rental.id === rentalId);

    return rentals;
  }, [rentalId, rentals]);

  const { claimId } = useParams<"claimId">();
  const currentClaims = useMemo(() => {
    if (claimId) return claims?.filter((claim) => claim.id === claimId);

    return claims;
  }, [claimId, claims]);

  return {
    rentals: rentals?.length,
    rentalStatus: currentRentals?.map((rental) => rental.status).join(","),
    claims: claims?.length,
    claimType: currentClaims?.map((claim) => claim.incident_type).join(","),
    claimStatus: currentClaims?.map((claim) => claim.status).join(","),
  };
};
