import { Group, Stack, Text, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { Button } from "components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { RentalItem } from "types";

const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  buttonsWrapper: {
    alignSelf: "flex-end",
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignSelf: "stretch",
      flexDirection: "column-reverse",
      alignItems: "stretch",
    },
  },
}));

export const useClaimCreation = () => {
  const { t } = useTranslation();
  const [openedClaimCreation, { open: _openClaimCreation, close: _closeClaimCreation }] =
    useDisclosure();
  const [rentalItem, setRentalItem] = useState<RentalItem>();
  const [retailerId, setRetailerId] = useState<string>();
  const [isClaimCreated, setClaimCreated] = useState(false);
  const { classes } = useStyles();

  const openClaimCreation = useCallback(
    (rentalItem: RentalItem, retailerId: string) => {
      setRentalItem(rentalItem);
      setRetailerId(retailerId);
      setClaimCreated(false);
      _openClaimCreation();
    },
    [_openClaimCreation]
  );

  const closeClaimCreation = useCallback(
    (action?: () => void) => {
      if (!!action && openedClaimCreation && !isClaimCreated) {
        modals.open({
          title: t("claim.areYouSureToQuitThisPage"),
          children: (
            <Stack>
              <Text>{t("claim.yourClaimCreationWillNotBeSaved")}</Text>

              <Group spacing="xs" className={classes.buttonsWrapper}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    modals.closeAll();
                    _closeClaimCreation();
                    action();
                  }}
                >
                  {t("claim.quit")}
                </Button>
                <Button variant="primary" onClick={() => modals.closeAll()}>
                  {t("claim.continue")}
                </Button>
              </Group>
            </Stack>
          ),
          size: "lg",
        });
      } else {
        _closeClaimCreation();
        action?.();
      }
    },
    [openedClaimCreation, isClaimCreated, t, classes.buttonsWrapper, _closeClaimCreation]
  );

  return {
    openedClaimCreation,
    rentalItemClaimCreation: rentalItem,
    retailerIdClaimCreation: retailerId,
    openClaimCreation,
    closeClaimCreation,
    onClaimCreated: () => setClaimCreated(true),
  };
};
