export enum RoutePath {
  LOGIN = "/",

  RENTALS = "/rentals",
  RENTAL = "/rentals/:rentalId",

  ORDERS = "/orders",
  ORDER = "/orders/:sessionId",

  CLAIMS = "/claims",
  CLAIM = "/claims/:claimId",

  PROFILE = "/profile",

  ERROR = "/error",
}
