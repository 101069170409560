import { claimFileSchema, claimSchema } from "schemas";
import { z } from "zod";

export enum IncidentType {
  DAMAGE = "damage",
  BREAKDOWN = "breakdown",
  THEFT = "theft",
  OTHER = "other",
}

export enum ClaimStatus {
  REVIEWING = "reviewing",
  REVIEWING_DECLARATION = "reviewing_declaration",
  PROCESSING = "processing",
  REFUSED = "refused",
  CLOSED = "closed",
  OPEN = "open",
}

export type ClaimCreation = {
  rental_item_id: string;
  incident_type: IncidentType;
  incident_date: string;
  incident_description: string;
  files: File[];
};

export type Claim = z.infer<typeof claimSchema>;

export type ClaimFile = z.infer<typeof claimFileSchema>;
