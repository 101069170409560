import { Chip, Stack, Text } from "@mantine/core";
import { Button } from "components";
import { useEventTracking } from "hooks/analytics";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { IncidentType } from "types";

interface Props {
  incidentTypes: IncidentType[];
  setSelectedIncidentType: (incidentType: IncidentType) => void;
}

export const ClaimIncidentTypeSelect: React.FC<Props> = ({
  incidentTypes,
  setSelectedIncidentType,
}) => {
  const { t } = useTranslation();
  const [incidentType, setIncidentType] = useState<IncidentType>(incidentTypes[0]);
  const { trackClick } = useEventTracking();

  return (
    <Stack
      sx={({ fn: { smallerThan } }) => ({
        [smallerThan(SMALL_SCREEN_BREAKPOINT)]: { flex: 1, justifyContent: "space-between" },
      })}
      spacing="xl"
    >
      <Stack>
        <Text weight={700}>{t("claim.selectCause")}</Text>
        <Chip.Group
          value={incidentType}
          onChange={(incidentType: IncidentType) => setIncidentType(incidentType)}
        >
          <Stack align="stretch" spacing="xs">
            {incidentTypes.map((incidentType) => (
              <Chip
                key={incidentType}
                value={incidentType}
                data-testid={`claim-incident-type-${incidentType}`}
              >
                {t(`claim.incidentLongTypes.${incidentType}`)}
              </Chip>
            ))}
          </Stack>
        </Chip.Group>
      </Stack>

      <Button
        sx={({ fn: { largerThan } }) => ({
          [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
        })}
        onClick={() => {
          setSelectedIncidentType(incidentType);
          trackClick(TrackingEventCategory.BUTTON, "click_continue_claim");
        }}
        data-testid="claim-incident-type-submit-button"
      >
        {t("claim.next")}
      </Button>
    </Stack>
  );
};
