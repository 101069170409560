import { Item } from "components";
import { LineItem, LineItemType } from "types";

export type OrderItem = Item & {
  coverage_configuration_code: string | null;
};

const getLineItemSelectedPlan = (lineItem: LineItem, selectedDuration: number) => {
  const selectedPlan = lineItem.plans.find(({ duration }) => duration === selectedDuration);

  if (!selectedPlan)
    throw Error(`No plan found for line item ${lineItem.id} with duration: ${selectedDuration}`);

  return selectedPlan;
};

export const buildOrderItems = (lineItems: LineItem[], selectedDuration: number): OrderItem[] =>
  lineItems
    .filter((lineItem) => lineItem.item_type === LineItemType.PHYSICAL)
    .flatMap((lineItem) => {
      const first_instalment_with_tax = Math.round(
        getLineItemSelectedPlan(lineItem, selectedDuration).first_instalment_with_tax /
          lineItem.quantity
      );
      const other_instalment_with_tax = Math.round(
        getLineItemSelectedPlan(lineItem, selectedDuration).other_instalment_with_tax /
          lineItem.quantity
      );

      return Array.from({ length: lineItem.quantity }).map((_, index) => ({
        ...lineItem,
        id: `${lineItem.id}_${index}`,
        serial_number: lineItem.serial_numbers.at(index) ?? null,
        first_instalment_with_tax,
        other_instalment_with_tax,
        duration: selectedDuration,
        rental_end_date: null,
        rental_start_date: null,
      }));
    });
