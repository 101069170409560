import { Anchor, Loader, PinInput, Stack, Text } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";
import { Button } from "components";
import { useAuthContext } from "contexts/AuthContext";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateCustomerPhone as _updateCustomerPhone } from "services/api/customer";
import { BLUE, SMALL_SCREEN_BREAKPOINT } from "theme";
import { z } from "zod";
import { useStyles } from "./ProfileCustomerPhoneValidationCodeInput.styles";

interface Props {
  customerId: string;
  onCustomerPhoneUpdated: () => void;
  onCancel: () => void;
  attemptsLeft: number;
  setAttemptsLeft: (attemptsLeft: number) => void;
  closeModal: () => void;
}

export const ProfileCustomerPhoneValidationCodeInput: React.FC<Props> = ({
  customerId,
  onCustomerPhoneUpdated,
  onCancel,
  attemptsLeft,
  setAttemptsLeft,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const { logOut } = useAuthContext();
  const { classes } = useStyles();

  const noAttemptsLeft = useMemo(() => attemptsLeft <= 0, [attemptsLeft]);

  const {
    mutate: updateCustomerPhone,
    isLoading: isUpdatingCustomerPhone,
    isError,
    reset,
  } = useMutation({
    mutationFn: _updateCustomerPhone,
    onMutate: () => setIsCodeInvalid(false),
    onSuccess: onCustomerPhoneUpdated,
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
        try {
          setAttemptsLeft(
            z.object({ attempts_left: z.number() }).parse(error.response?.data).attempts_left
          );
          setIsCodeInvalid(true);
        } catch {
          logOut();
        }
      }
    },
  });

  return (
    <Stack justify="space-between" sx={{ flex: 1 }}>
      <Stack>
        <Text>{t("profile.fillValidationCodeReceivedBySms")}</Text>

        <PinInput
          length={6}
          size="md"
          type="number"
          oneTimeCode
          onComplete={(phone_validation_code) =>
            updateCustomerPhone({ customerId, phone_validation_code })
          }
          error={isError}
          onChange={reset}
          autoFocus
          disabled={noAttemptsLeft}
          classNames={classes}
        />

        {isUpdatingCustomerPhone ? (
          <Loader size="sm" sx={{ alignSelf: "center" }} />
        ) : isCodeInvalid ? (
          <Text color="red">
            {t("login.invalidCode")}
            {attemptsLeft !== undefined && (
              <Text span> {t("profile.attemptsLeft", { count: Math.max(0, attemptsLeft) })}</Text>
            )}
          </Text>
        ) : (
          noAttemptsLeft && <Text color="red">{t("profile.attemptsLeft_zero")}</Text>
        )}
      </Stack>

      {noAttemptsLeft ? (
        <Button
          sx={({ fn: { largerThan } }) => ({
            [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
          })}
          onClick={closeModal}
        >
          {t("profile.quit")}
        </Button>
      ) : (
        <Text>
          <Text span>{t("profile.youDidNotReceivedTheCode")}</Text>{" "}
          <Anchor color={BLUE} td="underline" onClick={onCancel} fw={700}>
            {t("profile.sendBack")}
          </Anchor>
        </Text>
      )}
    </Stack>
  );
};
