import {
  ActionIcon,
  FileButton,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IconFileUpload, IconX } from "@tabler/icons-react";
import { Button, DateStringPickerInput } from "components";
import { useForm } from "hooks";
import { useEventTracking, useFormTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { enumFrom } from "schemas";
import { TrackingEventCategory } from "services/analytics";
import { BLUE, DARK_GRAY, LIGHT_BLUE, SMALL_SCREEN_BREAKPOINT } from "theme";
import { ClaimCreation, IncidentType } from "types";
import { z } from "zod";
import { useStyles } from "./ClaimCreationForm.styles";

interface Props {
  rentalItemId: string;
  incidentType: IncidentType;
  createClaim: (claim: ClaimCreation) => void;
  isCreatingClaim: boolean;
}

const MAX_FILES = 5;
const MAX_FILE_SIZE = 20 * 1024 ** 2; // 20MB

export const ClaimCreationForm: React.FC<Props> = ({
  rentalItemId,
  incidentType,
  createClaim,
  isCreatingClaim,
}) => {
  const { t } = useTranslation();
  const today = useMemo(() => new Date(), []);
  const { classes } = useStyles();
  const { trackClick } = useEventTracking();
  const { formProps } = useFormTracking("claim-form");

  const {
    values: { files },
    onSubmit,
    getInputProps,
    errors,
    setFieldValue,
  } = useForm({
    schema: z.object({
      rental_item_id: z.string(),
      incident_type: enumFrom(IncidentType),
      incident_date: z.string().nonempty(String(t("claim.requiredField"))),
      incident_description: z.string().nonempty(String(t("claim.requiredField"))),
      files: z
        .array(z.instanceof(File))
        .min(
          incidentType === "theft" ? 1 : 0,
          String(t("claim.theftReportOrFilingComplaintRequiredMessage"))
        )
        .refine((files) => files.every((file) => file.size <= MAX_FILE_SIZE), {
          message: String(t("claim.maxFileSizeExceeded")),
        }),
    }),
    initialValues: {
      rental_item_id: rentalItemId,
      incident_type: incidentType,
      incident_date: "",
      incident_description: "",
      files: [],
    },
  });

  const addFiles = (newFiles: File[]) =>
    setFieldValue("files", [...files, ...newFiles].slice(0, MAX_FILES));

  return (
    <form
      onSubmit={onSubmit((claimCreation) => createClaim(claimCreation))}
      noValidate
      className={classes.form}
      {...formProps}
    >
      <Stack className={classes.wrapper}>
        <Stack>
          <Title order={2} size="h6">
            {t(`claim.incidentLongTypes.${incidentType}`)}.
          </Title>

          <Stack spacing="xs">
            <Text weight={700}>{t("claim.specifyIncidentDate")}</Text>
            <DateStringPickerInput
              w={200}
              placeholder={String(t("claim.incidentDatePlaceholder"))}
              maxDate={today}
              {...getInputProps("incident_date")}
              name="claim-incident-date"
              data-testid="claim-incident-date-picker"
            />
          </Stack>

          <Stack spacing="xs">
            <Text weight={700}>{t("claim.describeIncident")}</Text>
            <Textarea
              autosize
              minRows={3}
              {...getInputProps("incident_description")}
              name="claim-incident-description"
              data-testid="claim-incident-description-input"
            />
          </Stack>

          {incidentType === "theft" && (
            <Stack spacing="xs">
              <Text weight={700}>{t("claim.uploadTheftReportOrFilingComplaint")}</Text>

              <Dropzone
                onDrop={addFiles}
                accept={["image/jpeg", "image/png", "application/pdf"]}
                disabled={files.length === MAX_FILES}
                className={classes.dropzone}
                name="claim-incident-files"
                data-testid="claim-incident-files-dropzone"
              >
                <Stack spacing={0}>
                  <Text color={BLUE} align="center">
                    {t("claim.dragAndDropOrClickToAdd")}
                  </Text>
                  <Text align="center">{t("claim.uploadedFilesDescription")}</Text>
                </Stack>
              </Dropzone>

              <FileButton
                onChange={addFiles}
                accept="image/jpeg, image/png, application/pdf"
                name="claim-incident-files"
                multiple
              >
                {(props) => (
                  <Button
                    variant="secondary"
                    leftIcon={<IconFileUpload />}
                    className={classes.fileUploadButton}
                    {...props}
                  >
                    {t("claim.browseMyFiles")}
                  </Button>
                )}
              </FileButton>

              {files.length > 0 && (
                <SimpleGrid
                  cols={2}
                  verticalSpacing="xs"
                  spacing="xs"
                  breakpoints={[{ maxWidth: SMALL_SCREEN_BREAKPOINT, cols: 1 }]}
                >
                  {files.map((file, index) => (
                    <Group
                      key={`${file.name}-${index}`}
                      position="apart"
                      spacing="sm"
                      p="xs"
                      pl="sm"
                      align="center"
                      sx={({ radius }) => ({
                        borderRadius: radius.md,
                        backgroundColor: LIGHT_BLUE,
                      })}
                    >
                      <Text weight={700} size="sm" color={DARK_GRAY}>
                        {file.name}
                      </Text>
                      <ActionIcon
                        variant="transparent"
                        color={DARK_GRAY}
                        onClick={() =>
                          setFieldValue(
                            "files",
                            files.slice(0, index).concat(files.slice(index + 1))
                          )
                        }
                      >
                        <IconX />
                      </ActionIcon>
                    </Group>
                  ))}
                </SimpleGrid>
              )}

              {errors["files"] && (
                <Text color="red" size="sm">
                  {errors["files"]}
                </Text>
              )}
            </Stack>
          )}
        </Stack>

        <Button
          type="submit"
          loading={isCreatingClaim}
          data-testid="claim-incident-submit-button"
          onClick={() => trackClick(TrackingEventCategory.LINK, "click_send_claim")}
          className={classes.submitButton}
        >
          {t("claim.sendMyReport")}
        </Button>
      </Stack>
    </form>
  );
};
