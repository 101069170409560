import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(
  ({ spacing, fontSizes, fn: { smallerThan, largerThan } }) => ({
    root: {
      alignSelf: "center",
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        gap: spacing.sm,
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        gap: spacing.xs,
      },
    },
    input: {
      fontSize: fontSizes.lg,
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        width: "3rem",
        height: "4rem",
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        width: "2.5rem",
        height: "3.5rem",
      },
    },
  })
);
