import { Global, TextInputProps } from "@mantine/core";
import type { PKAOptions } from "@placekit/autocomplete-js";
import "@placekit/autocomplete-js/dist/placekit-autocomplete.css";
import { Handlers, usePlaceKit } from "@placekit/autocomplete-react";
import type { PKOptions } from "@placekit/client-js";
import placekit from "@placekit/client-js";
import { TextInput } from "components";
import { uniqueId } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./AddressAutocompleteInput.styles";
import { buildAddress, formatAddress, formatAddressCity } from "./helpers";
import { Address } from "./types";

type Props = Omit<
  TextInputProps,
  "classNames" | "color" | "style" | "value" | "onChange" | "onBlur"
> & {
  initialAddress?: Address;
  onChange: (address: Address | null) => void;
};

const PLACEKIT_BASE_CONFIG: PKOptions = {
  language: "fr",
  countries: ["fr"],
  types: ["street"],
  maxResults: 10,
};

export const AddressAutocompleteInput: React.FC<Props> = ({
  initialAddress = null,
  onChange,
  ...props
}) => {
  const [panelClassName] = useState(`panel-${uniqueId()}`);
  const formattedAddress = useRef(formatAddress(initialAddress));
  const [empty, setEmpty] = useState(true);
  const { styles } = useStyles();
  const { t } = useTranslation();

  const setAddress = useCallback(
    (newAddress: Address) => {
      onChange(newAddress);
      formattedAddress.current = formatAddress(newAddress);
      return formattedAddress.current;
    },
    [onChange]
  );

  const handlers: Partial<Handlers> = useMemo(
    () => ({
      onPick: (_value, item) => setAddress(buildAddress(item)),
      onClose: () => {
        if (target.current?.value !== formattedAddress.current) {
          onChange(null);
        }
      },
      onOpen: () => {
        // hack to allow scroll (which was removed in modal)
        document
          .querySelector(`.${panelClassName}`)
          ?.addEventListener("wheel", (event) => event.stopPropagation());
      },
      onResults: () =>
        document.querySelector(`.${panelClassName} .pka-panel-suggestions`)?.scrollTo({ top: 0 }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, setAddress]
  );

  const format: PKAOptions["format"] = useMemo(
    () => ({
      noResults: (query) => t(`address-autocomplete-input.noResultsForQuery`, { query }),
      value: (item) => formatAddress(buildAddress(item)),
      sub: (item) => formatAddressCity(buildAddress(item)),
      applySuggestion: String(t("address-autocomplete-input.applySuggestion")),
      cancel: String(t("address-autocomplete-input.cancel")),
    }),
    [t]
  );

  const { target, client } = usePlaceKit(import.meta.env.VITE_PLACEKIT_PUBLIC_KEY, {
    ...PLACEKIT_BASE_CONFIG,
    handlers,
    format,
    panel: { flip: true, offset: 8, className: panelClassName },
  });

  const onInputChange: React.FormEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      client.setValue(value);

      if (!value !== empty) {
        setEmpty(!value);
      }
    },
    [client, empty]
  );

  useEffect(() => {
    // hack to prevent autocompletion
    target.current?.setAttribute("autocomplete", "new-password");

    if (!client) return;

    client.setValue(formattedAddress.current, true);

    if (!initialAddress) return;

    const pk = placekit(import.meta.env.VITE_PLACEKIT_PUBLIC_KEY);

    pk.search(`${initialAddress.streetAddress} ${initialAddress.city}`, PLACEKIT_BASE_CONFIG).then(
      ({ results }) => {
        if (results.length > 0) {
          const validatedAddress = buildAddress(results[0]);
          client.setValue(setAddress(validatedAddress), true);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!client]);

  return (
    <>
      <Global styles={styles} />
      <TextInput ref={target} empty={empty} onChange={onInputChange} {...props} />
    </>
  );
};
