import { SimpleGrid, Stack, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { DARK_GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";
import { Rental } from "types";

interface Props {
  returnRentals: Rental[];
}

export const ReturnDetails: React.FC<Props> = ({ returnRentals }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="lg">
      {returnRentals.map((rental) => {
        const returnItems = rental.rental_items.filter((item) => !!item.return_id);

        return (
          <Stack spacing="sm" key={rental.id}>
            <SimpleGrid cols={2} verticalSpacing="sm">
              <Text color={DARK_GRAY}>{t("return.retailer")}</Text>
              <Text weight={700}>{rental.retailer_infos.name}</Text>
              <Text color={DARK_GRAY}>{t("return.rental")}</Text>
              <Text weight={700}>{rental.reference}</Text>
            </SimpleGrid>

            <SimpleGrid
              cols={2}
              verticalSpacing="xs"
              sx={({ fn: { smallerThan } }) => ({
                [smallerThan(SMALL_SCREEN_BREAKPOINT)]: { gridTemplateColumns: "1fr" },
              })}
            >
              <Text color={DARK_GRAY}>
                {t("return.returnItemCount", { count: returnItems.length })}
              </Text>
              <Stack spacing="sm">
                {returnItems.map((item) => (
                  <Text weight={700} key={item.id}>
                    {item.name}
                  </Text>
                ))}
              </Stack>
            </SimpleGrid>
          </Stack>
        );
      })}
    </Stack>
  );
};
