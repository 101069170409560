import { Stack, Text, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { BadgeIcon, Button } from "components";
import { setCustomerQueryData } from "helpers/customer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Customer } from "types";
import { ProfileCustomerEmailUpdateForm } from "./Form";
import { ProfileCustomerEmailValidationCodeInput } from "./ValidationCodeInput";

interface Props {
  customer: Customer;
  closeModal: () => void;
}

export const ProfileCustomerEmailUpdate: React.FC<Props> = ({ customer, closeModal }) => {
  const [initialEmail, setInitialEmail] = useState(customer.email);
  const [newCustomerEmail, setNewCustomerEmail] = useState<string>();
  const [attemptsLeft, setAttemptsLeft] = useState<number>();
  const [isCustomerEmailUpdated, setIsCustomerEmailUpdated] = useState(false);
  const { t } = useTranslation();

  if (isCustomerEmailUpdated)
    return (
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ flex: 1 }} justify="center" align="center" spacing="sm">
          <BadgeIcon icon={IconCheck} state="positive" size={rem(40)} />
          <Text>{t("profile.updatedSuccessfully")}</Text>
        </Stack>

        <Button
          sx={({ fn: { largerThan } }) => ({
            [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
          })}
          onClick={closeModal}
        >
          {t("profile.quit")}
        </Button>
      </Stack>
    );

  if (newCustomerEmail && attemptsLeft !== undefined)
    return (
      <ProfileCustomerEmailValidationCodeInput
        customerId={customer.id}
        onCustomerEmailUpdated={() => {
          setCustomerQueryData({ ...customer, email: String(newCustomerEmail) });
          setIsCustomerEmailUpdated(true);
        }}
        onCancel={() => {
          setInitialEmail(newCustomerEmail);
          setNewCustomerEmail(undefined);
        }}
        attemptsLeft={attemptsLeft}
        setAttemptsLeft={setAttemptsLeft}
        closeModal={closeModal}
      />
    );

  return (
    <ProfileCustomerEmailUpdateForm
      customerId={customer.id}
      initialEmail={initialEmail}
      setNewCustomerEmail={setNewCustomerEmail}
      setAttemptsLeft={setAttemptsLeft}
      closeModal={closeModal}
    />
  );
};
