import { Group, Modal, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronRight, IconPencil } from "@tabler/icons-react";
import { Button } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";
import { Customer } from "types";
import { useStyles } from "./ProfileCustomerBillingAddress.styles";
import { ProfileCustomerBillingAddressUpdate } from "./Update";

interface Props {
  customer: Customer;
}

export const ProfileCustomerBillingAddress: React.FC<Props> = ({ customer }) => {
  const [updateModalOpened, { open: openUpdateModal, close: closeUpdateModal }] = useDisclosure();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT - 1}px)`);

  const { billing_address } = customer;

  return (
    <>
      <Paper
        radius="md"
        withBorder
        p="sm"
        className={classes.container}
        onClick={() => isSmallScreen && openUpdateModal()}
      >
        <Group position="apart" spacing="xl">
          <Stack spacing="xs">
            <Text fw={700}>{t("profile.billingAddress")}</Text>
            <Text>
              {billing_address
                ? `${billing_address.street_address}, ${billing_address.zip_code} ${billing_address.city}`
                : "-"}
            </Text>
            {billing_address?.street_address_2 && <Text>{billing_address.street_address_2}</Text>}
          </Stack>

          <Button
            variant="ghost"
            leftIcon={<IconPencil />}
            className={classes.editButton}
            onClick={openUpdateModal}
          >
            {t("profile.edit")}
          </Button>

          <IconChevronRight color={GRAY} className={classes.chevronIcon} />
        </Group>
      </Paper>

      <Modal
        opened={updateModalOpened}
        onClose={closeUpdateModal}
        title={t("profile.billingAddress")}
      >
        <ProfileCustomerBillingAddressUpdate customer={customer} closeModal={closeUpdateModal} />
      </Modal>
    </>
  );
};
