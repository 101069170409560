import { createStyles } from "@mantine/core";
import { GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ headings, spacing, fn: { smallerThan } }) => ({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },

  title: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      fontSize: headings.sizes.h5.fontSize,
    },
  },

  openCustomerDetailsButton: {
    borderStyle: "solid",
    borderColor: GRAY,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    padding: `${spacing.sm} ${spacing.xs}`,
  },
}));
