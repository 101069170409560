import { useMatomoContext } from "contexts/MatomoContext";
import { useCallback } from "react";
import { TrackingEventAction, TrackingEventCategory, TrackingEventType } from "services/analytics";
import { useTrackingEventData } from "./useTrackingEventData";

export const useEventTracking = () => {
  const { trackEvent } = useMatomoContext();
  const eventData = useTrackingEventData();

  const trackVisit = useCallback(
    (name: string) => {
      trackEvent({
        type: TrackingEventType.EVENT,
        category: TrackingEventCategory.VISIT,
        action: TrackingEventAction.CONSULT,
        name,
        eventData,
      });
    },
    [eventData, trackEvent]
  );

  const trackClick = useCallback(
    (category: TrackingEventCategory.BUTTON | TrackingEventCategory.LINK, name: string) => {
      trackEvent({
        type: TrackingEventType.EVENT,
        action: TrackingEventAction.CLICK,
        category,
        name,
        eventData,
      });
    },
    [eventData, trackEvent]
  );

  return { trackVisit, trackClick };
};
