import { Group, Image, Paper, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Button, RentalStatusBadge } from "components";
import { useRetailerQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Rental } from "types";
import { useStyles } from "./RentalCard.styles";

interface Props {
  rental: Rental;
}

export const RentalCard: React.FC<Props> = ({ rental }) => {
  const { t } = useTranslation();
  const { id: rentalId, status } = rental;
  const { retailer } = useRetailerQuery(rental.retailer_infos.id);
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT - 1}px)`);

  return (
    <Paper
      shadow="md"
      p="xs"
      radius="md"
      className={classes.card}
      onClick={() => {
        if (isSmallScreen) {
          trackClick(TrackingEventCategory.LINK, "click_see_rental");
          navigate(rentalId);
        }
      }}
    >
      <Paper radius="md">
        <Group position="apart" align="stretch" className={classes.container}>
          <Stack spacing="xs" align="start" className={classes.leftSection}>
            <Stack spacing="xs">
              <Text tt="uppercase" weight={700}>
                {rental.retailer_infos.name}
              </Text>

              <Text size="xl" weight={700}>
                {t("rental.rentalLabel", { reference: rental.reference })}
              </Text>
            </Stack>

            <div className={classes.rentalStatusBadgeWrapper}>
              <RentalStatusBadge status={status} />
            </div>
          </Stack>

          <Stack spacing="xs" align="end" justify="space-between" className={classes.rightSection}>
            {retailer ? (
              <Image src={retailer.style.logo_url} fit="contain" height={50} width={120} />
            ) : (
              <div style={{ height: 50 }} />
            )}

            <Link
              to={rentalId}
              onClick={() => trackClick(TrackingEventCategory.LINK, "click_see_rental")}
            >
              <Button w="100%">{t("rental.consultMyRental")}</Button>
            </Link>
          </Stack>
        </Group>
      </Paper>
    </Paper>
  );
};
