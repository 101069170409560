import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getRentalPaymentInfo } from "helpers/rental";
import React, { useMemo } from "react";
import { Customer, Rental } from "types";
import { RentalPaymentMethodUpdateForm } from "./Form";

interface Props {
  rental: Rental;
  customer: Customer;
  onSuccess: () => void;
  onCancel: () => void;
}

export const RentalPaymentMethodUpdate: React.FC<Props> = ({
  rental,
  customer,
  onSuccess,
  onCancel,
}) => {
  const stripe = useMemo(
    () => loadStripe(rental.retailer_infos.payment_processor_public_api_key),
    [rental.retailer_infos.payment_processor_public_api_key]
  );
  const paymentMethod = useMemo(() => getRentalPaymentInfo(rental)?.payment_method, [rental]);

  if (!paymentMethod || !customer) return null;

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: "setup",
        currency: "eur",
        paymentMethodTypes: [paymentMethod],
      }}
    >
      <RentalPaymentMethodUpdateForm
        rental={rental}
        customer={customer}
        onSuccess={onSuccess}
        onCancel={onCancel}
      />
    </Elements>
  );
};
