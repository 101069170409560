import { Paper, Stack, Text, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ClaimSketch } from "resources/sketches/claim.svg";
import { useStyles } from "./NoClaims.styles";

export const NoClaims: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Paper p="md" className={classes.container}>
      <Stack spacing="sm" className={classes.textWrapper}>
        <Title order={2} size="h6">
          {t("claim.goodNews")}
        </Title>
        <Text>{t("claim.noReportedClaims")}</Text>
      </Stack>

      <ClaimSketch />
    </Paper>
  );
};
