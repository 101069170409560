import { PaymentMethod } from "@stripe/stripe-js";
import { apiClient } from "../client";

interface Params {
  rental_id: string;
  setup_intent_id: string;
  payment_method_id: string | PaymentMethod | null;
}

export const updateRentalPaymentInfo = async (params: Params) => {
  await apiClient.post(`v1/rentals/${params.rental_id}/update_payment_info`, params);
};
