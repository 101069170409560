import { Anchor, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import { BadgeIcon } from "components";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { Session, SessionDocumentType } from "types";
import { useStyles } from "./OrderLegalDocuments.styles";

interface Props {
  session: Session;
}

export const OrderLegalDocuments: React.FC<Props> = ({ session }) => {
  const { t } = useTranslation();
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();

  const rentalContractUrl = useMemo(
    () =>
      session.documents.find(
        (document) => document.document_type === SessionDocumentType.CALF_CONTRACT_SIGNED
      )?.files[0].url,
    [session.documents]
  );

  if (!session.rental_conditions_url && !rentalContractUrl) return null;

  return (
    <Stack spacing="sm">
      <Title size="h6" className={classes.title}>
        {t("order.legal")}
      </Title>

      <Paper withBorder p="sm" shadow="xs" radius="md">
        <Stack spacing="sm">
          {!!session.rental_conditions_url && (
            <>
              <Text weight={700}>{t("order.generalConditionsOfRental")}</Text>

              <Anchor
                href={session.rental_conditions_url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.fileLink}
                onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_download_CGL")}
              >
                <Group spacing="xs">
                  <BadgeIcon icon={IconArrowDown} state="message" bg="transparent" withBorder />

                  <Text span>
                    {t("order.retailerGeneralConditionsOfRental", {
                      retailerName: session.retailer_infos.name,
                    })}
                  </Text>
                </Group>
              </Anchor>
            </>
          )}

          {!!rentalContractUrl && (
            <>
              <Text weight={700}>{t("order.contracts")}</Text>

              <Anchor
                href={rentalContractUrl}
                td="underline"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.fileLink}
                onClick={() =>
                  trackClick(TrackingEventCategory.BUTTON, "click_download_rental_contract")
                }
              >
                <Group spacing="xs">
                  <BadgeIcon icon={IconArrowDown} state="message" bg="transparent" withBorder />

                  <Text span>{t("order.rentalContract")}</Text>
                </Group>
              </Anchor>
            </>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};
