import { ClaimStatus, IncidentType } from "types";
import { z } from "zod";
import { enumFrom } from "./utils";

export const claimRetailerInfosSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const claimRentalItemInfosSchema = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string().nullable(),
  serial_number: z.string().nullable(),
});

export const claimFileSchema = z.object({
  id: z.string(),
  filename: z.string(),
  url: z.string(),
});

export const claimDocumentSchema = z
  .object({
    id: z.string(),
    files: z.array(claimFileSchema).nullable(),
  })
  .passthrough();

export const claimSchema = z.object({
  id: z.string(),
  declaration_date: z.string(),
  status: enumFrom(ClaimStatus).nullable(),
  incident_type: enumFrom(IncidentType),
  incident_date: z.string(),
  incident_description: z.string(),
  rental_item_infos: claimRentalItemInfosSchema,
  retailer_infos: claimRetailerInfosSchema,
  claim_document: claimDocumentSchema.nullable().optional(),
});
