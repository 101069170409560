import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  footer: {
    position: "unset",
    padding: `${spacing.md} ${spacing.xl}`,
    backgroundColor: "#F7F8F9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: spacing.md,
    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },
}));
