import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  backToRentalsLink: {
    display: "block",
    marginBottom: spacing.lg,
  },

  orderHeader: {
    alignItems: "flex-end",
    columnGap: spacing.md,
    rowGap: spacing.xs,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
    },
  },

  panel: {
    marginTop: spacing.lg,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "block",
    },
  },

  tabsList: {
    marginTop: spacing.md,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },
}));
