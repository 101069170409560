import { Anchor, Center, Flex, Group, Tabs, Text, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { SessionStatusBadge } from "components";
import { useSession } from "hooks";
import { useEventTracking, usePageViewTracking } from "hooks/analytics";
import pick from "lodash/pick";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { RoutePath } from "types";
import { useStyles } from "./OrderPage.styles";
import { OrderBilling, OrderDetails } from "./components";

export const OrderPage: React.FC = () => {
  usePageViewTracking("session-details");
  const { t } = useTranslation();
  const { sessionId } = useParams<"sessionId">();
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();

  const session = useSession(String(sessionId));

  if (session === null) return null;

  if (session === undefined)
    return (
      <Center>
        <Text weight={600}>{t("order.orderNotFound")}</Text>
      </Center>
    );

  return (
    <div>
      <Anchor component={Link} to={RoutePath.ORDERS} className={classes.backToRentalsLink}>
        <Group spacing="xs">
          <IconArrowLeft />
          <Text weight={700} span>
            {t("order.backToOrders")}
          </Text>
        </Group>
      </Anchor>

      <Flex className={classes.orderHeader}>
        <Title size="h4">{t("order.orderLabel", { reference: session.reference })}</Title>

        <SessionStatusBadge status={session.status} />
      </Flex>

      <Tabs defaultValue="details" classNames={pick(classes, "panel", "tabsList")}>
        <Tabs.List>
          <Tabs.Tab
            value="details"
            onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_tab_session_details")}
          >
            {t("order.details")}
          </Tabs.Tab>
          <Tabs.Tab
            value="billing"
            onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_tab_session_billing")}
          >
            {t("order.billing")}
          </Tabs.Tab>
        </Tabs.List>

        <Flex direction="column">
          <Tabs.Panel value="details">
            <OrderDetails session={session} />
          </Tabs.Panel>

          <Tabs.Panel value="billing">
            <OrderBilling session={session} />
          </Tabs.Panel>
        </Flex>
      </Tabs>
    </div>
  );
};
