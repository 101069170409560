import { withSentryReactRouterV6Routing } from "@sentry/react";
import { AppLayout, RequireAuth } from "components";
import {
  ClaimPage,
  ClaimsPage,
  ErrorPage,
  LoginPage,
  OrderPage,
  OrdersPage,
  ProfilePage,
  RentalPage,
  RentalsPage,
} from "pages";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { RoutePath } from "types";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const AppRoutes: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SentryRoutes>
      <Route path={RoutePath.LOGIN} element={<LoginPage />} />

      <Route
        path={RoutePath.RENTALS}
        element={
          <RequireAuth>
            <AppLayout>
              <RentalsPage />
            </AppLayout>
          </RequireAuth>
        }
      />
      <Route
        path={RoutePath.RENTAL}
        element={
          <RequireAuth>
            <AppLayout>
              <RentalPage />
            </AppLayout>
          </RequireAuth>
        }
      />

      <Route
        path={RoutePath.ORDERS}
        element={
          <RequireAuth>
            <AppLayout>
              <OrdersPage />
            </AppLayout>
          </RequireAuth>
        }
      />
      <Route
        path={RoutePath.ORDER}
        element={
          <RequireAuth>
            <AppLayout>
              <OrderPage />
            </AppLayout>
          </RequireAuth>
        }
      />

      <Route
        path={RoutePath.CLAIMS}
        element={
          <RequireAuth>
            <AppLayout>
              <ClaimsPage />
            </AppLayout>
          </RequireAuth>
        }
      />
      <Route
        path={RoutePath.CLAIM}
        element={
          <RequireAuth>
            <AppLayout>
              <ClaimPage />
            </AppLayout>
          </RequireAuth>
        }
      />

      <Route
        path={RoutePath.PROFILE}
        element={
          <RequireAuth>
            <AppLayout>
              <ProfilePage />
            </AppLayout>
          </RequireAuth>
        }
      />

      <Route path={RoutePath.ERROR} element={<ErrorPage />} />
    </SentryRoutes>
  );
};
