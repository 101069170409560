import { BrowserTracing, Replay, init, reactRouterV6Instrumentation } from "@sentry/react";
import { useEffect, useMemo } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Mode } from "types";

export const useSentry = () => {

  useMemo(() => {
    if (import.meta.env.MODE !== Mode.PRODUCTION && import.meta.env.MODE !== Mode.STAGING && import.meta.env.MODE !== Mode.DEVELOPMENT) return;

    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.MODE,
      release: import.meta.env.VITE_SENTRY_RELEASE,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        new Replay(),
      ],
      tracesSampleRate: import.meta.env.MODE === Mode.PRODUCTION ? 0.2 : 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      normalizeDepth: 10,
    });
  }, []);
};
