import { Group, Image, Paper, Stack, Text } from "@mantine/core";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { BLUE } from "theme";
import { Claim } from "types";

interface Props {
  claim: Claim;
}

export const ClaimCard: React.FC<Props> = ({ claim }) => {
  const { t } = useTranslation();
  const { id: claimId, rental_item_infos, retailer_infos, status } = claim;
  const { trackClick } = useEventTracking();

  return (
    <Paper
      component={Link}
      to={claimId}
      onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_see_claim")}
      shadow="md"
      p="sm"
      radius="md"
      withBorder
      sx={{ cursor: "pointer" }}
    >
      <Group align="center" spacing="lg">
        <Image src={rental_item_infos.image} height={80} width={80} fit="cover" radius="sm" />
        <Stack spacing="xs">
          <Text weight={700}>{retailer_infos.name}</Text>
          <Text weight={700}>{rental_item_infos.name}</Text>
          <Text color={BLUE}>{t(`claim.claimStatuses.${status}`)}</Text>
        </Stack>
      </Group>
    </Paper>
  );
};
