import { Group, Paper, Stack, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { InvoicePaymentInfo } from "types";
import { PaymentMethodIcon } from "./PaymentMethodIcon";

interface Props {
  paymentInfo: InvoicePaymentInfo;
}

export const PaymentMethodDetails: React.FC<Props> = ({ paymentInfo }) => {
  const { t } = useTranslation();

  return (
    <Paper withBorder p="sm" shadow="xs" radius="md">
      <Stack spacing="sm">
        <Text weight={700}>{t(`paymentMethods.${paymentInfo.payment_method}.label`)}</Text>

        <Group spacing="sm">
          <PaymentMethodIcon
            paymentMethod={paymentInfo.payment_method}
            brand={paymentInfo.payment_method_brand}
          />

          <Text>
            {t(`paymentMethods.${paymentInfo.payment_method}.preview`, {
              last4: paymentInfo.payment_method_last4,
              last3: paymentInfo.payment_method_last4.slice(1),
            })}
          </Text>
        </Group>
      </Stack>
    </Paper>
  );
};
