import { createStyles } from "@mantine/core";
import {
  BLUE,
  DARK_GREEN,
  DARK_ORANGE,
  DARK_RED,
  LIGHT_BLUE,
  LIGHT_GREEN,
  LIGHT_ORANGE,
  LIGHT_RED,
} from "theme";

export type BadgeState = "message" | "positive" | "negative" | "warning";

export const BADGE_COLORS_BY_STATE: Record<BadgeState, { color: string; backgroundColor: string }> =
  {
    message: { color: BLUE, backgroundColor: LIGHT_BLUE },
    positive: { color: DARK_GREEN, backgroundColor: LIGHT_GREEN },
    negative: { color: DARK_RED, backgroundColor: LIGHT_RED },
    warning: { color: DARK_ORANGE, backgroundColor: LIGHT_ORANGE },
  };

interface Params {
  state: BadgeState;
}

export const useStyles = createStyles<string, Params>(({ spacing }, { state }) => {
  const { color, backgroundColor } = BADGE_COLORS_BY_STATE[state];

  return {
    root: {
      paddingLeft: spacing.sm,
      paddingRight: spacing.sm,
      backgroundColor,
    },
    inner: {
      textTransform: "none",
      fontWeight: 400,
      color,
    },
  };
});
