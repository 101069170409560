import { Anchor, Loader, PinInput, Stack, Text, Title } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";
import { useAuthContext } from "contexts/AuthContext";
import { getCustomerCredentialValue } from "helpers/customer";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { validateAuthCode as _validateAuthCode } from "services/api/customer";
import { BLUE } from "theme";
import { CustomerCredential } from "types";
import { z } from "zod";
import { useStyles } from "./LoginAuthCodeInput.styles";

interface Props {
  customerCredential: CustomerCredential;
  onCancel: () => void;
  attemptsLeft: number;
  setAttemptsLeft: (attemptsLeft: number) => void;
}

export const LoginAuthCodeInput: React.FC<Props> = ({
  customerCredential,
  onCancel,
  attemptsLeft,
  setAttemptsLeft,
}) => {
  const { t } = useTranslation();
  const { email, phone, type } = useMemo(
    () => getCustomerCredentialValue(customerCredential),
    [customerCredential]
  );
  const { logIn } = useAuthContext();
  const { trackClick, trackVisit } = useEventTracking();
  const [isAuthCodeInvalid, setIsAuthCodeInvalid] = useState(false);
  const { classes } = useStyles();

  const noAttemptsLeft = useMemo(() => attemptsLeft <= 0, [attemptsLeft]);

  const {
    mutate: validateAuthCode,
    isLoading: isValidatingAuthCode,
    isError,
    reset,
  } = useMutation({
    mutationFn: _validateAuthCode,
    onMutate: () => setIsAuthCodeInvalid(false),
    onSuccess: ({ api_key, customer_id }) => logIn(customer_id, api_key),
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
        setAttemptsLeft(
          z.object({ attempts_left: z.number() }).parse(error.response?.data).attempts_left
        );
        trackVisit("error_authentification");
        setIsAuthCodeInvalid(true);
      }
    },
  });

  return (
    <Stack align="center" w={650}>
      <Title size="h4" align="center">
        {t(`login.getTheCode.${type}.title`)}
      </Title>

      <Text align="center"> {t(`login.getTheCode.${type}.message`, { email, phone })}</Text>

      <PinInput
        length={6}
        type="number"
        oneTimeCode
        onComplete={(auth_code) => validateAuthCode({ auth_code, ...customerCredential })}
        error={isError}
        onChange={reset}
        autoFocus
        data-testid="login-code-input-group"
        disabled={noAttemptsLeft}
        classNames={classes}
      />

      {isValidatingAuthCode ? (
        <Loader size="sm" />
      ) : isAuthCodeInvalid ? (
        <Text size="xs" color="red" align="center">
          {t("login.invalidCode")}
          {attemptsLeft !== undefined && (
            <Text span> {t("login.attemptsLeft", { count: Math.max(0, attemptsLeft) })}</Text>
          )}
        </Text>
      ) : (
        noAttemptsLeft && (
          <Text size="xs" color="red" align="center">
            {t("login.attemptsLeft_zero")}
          </Text>
        )
      )}

      {!noAttemptsLeft && (
        <Text size="xs">
          <Text span>{t("login.youDidNotReceivedTheCode")}</Text>{" "}
          <Anchor
            color={BLUE}
            td="underline"
            onClick={() => {
              onCancel();
              trackClick(TrackingEventCategory.LINK, "click_resend_code");
            }}
            data-testid="login-code-input-send-back"
          >
            {t("login.sendBackTheCode")}
          </Anchor>
        </Text>
      )}
    </Stack>
  );
};
