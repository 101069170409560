import { Badge, BadgeState } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { SessionStatus } from "types";

interface Props {
  status: SessionStatus;
}

const BADE_STATE_BY_STATUS: Record<SessionStatus, BadgeState> = {
  [SessionStatus.CREATED]: "message",
  [SessionStatus.ID_VERIFIED]: "message",
  [SessionStatus.ACTIVE]: "positive",
  [SessionStatus.PREPARING_PAYMENT]: "positive",
  [SessionStatus.WEB_SCORING_REJECTED]: "negative",
  [SessionStatus.OPEN_BANKING_SCORING_REJECTED]: "negative",
  [SessionStatus.BLOCKED]: "negative",
  [SessionStatus.PENDING_DOCUMENTS]: "warning",
  [SessionStatus.PENDING_E_SIGNATURE]: "warning",
  [SessionStatus.PENDING_REVIEW]: "message",
  [SessionStatus.REVIEWED]: "message",
  [SessionStatus.CANCELED]: "negative",
  [SessionStatus.REJECTED]: "negative",
  [SessionStatus.EXPIRED]: "negative",
  [SessionStatus.COMPLETE]: "positive",
  [SessionStatus.CALF_REJECTED]: "negative",
};

export const SessionStatusBadge: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  return <Badge state={BADE_STATE_BY_STATUS[status]}>{t(`order.sessionStatuses.${status}`)}</Badge>;
};
