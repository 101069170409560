import { useMemo } from "react";
import { useRentalsQuery } from "./useRentalsQuery";

export const useRental = (rentalId: string) => {
  const { rentals, isLoading } = useRentalsQuery();

  const rental = useMemo(() => {
    if (isLoading) return null;

    return rentals?.find((rental) => rental.id === rentalId);
  }, [isLoading, rentalId, rentals]);

  return rental;
};
