import { Group, Stack } from "@mantine/core";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";
import { Button } from "components";
import { invalidateRentalsQuery } from "helpers/rental";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { getRentalPaymentSecret, updateRentalPaymentInfo } from "services/api/rental";
import { Customer, Rental } from "types";
import { useStyles } from "./RentalPaymentMethodUpdateForm.styles";

interface Props {
  rental: Rental;
  customer: Customer;
  onSuccess: () => void;
  onCancel: () => void;
}

export const RentalPaymentMethodUpdateForm: React.FC<Props> = ({
  rental,
  customer,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();

  const stripe = useStripe();
  const elements = useElements();

  const { mutate: submitPaymentMethod, isLoading: isSubmittingPaymentMethod } = useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) return;

      if (!customer.billing_address)
        throw Error(`Customer ${customer.id} does not have a billing address.`);

      const { error } = await elements.submit();

      if (error) throw Error(error.message);

      const paymentSecret = (await getRentalPaymentSecret(rental.id)).payment_secret;

      const result = await stripe.confirmSetup({
        elements,
        clientSecret: paymentSecret,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: customer.first_name + " " + customer.last_name,
              phone: customer.phone,
              email: customer.email,
              address: {
                line1: customer.billing_address.street_address,
                line2: customer.billing_address.street_address_2 ?? undefined,
                city: customer.billing_address.city,
                state: "",
                postal_code: customer.billing_address.zip_code,
                country: customer.billing_address.country,
              },
            },
          },
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      const setupIntent = result.setupIntent ?? result.error.setup_intent;

      if (!setupIntent) throw Error(result.error?.message);

      return updateRentalPaymentInfo({
        rental_id: rental.id,
        setup_intent_id: setupIntent.id,
        payment_method_id: setupIntent.payment_method,
      });
    },
    onSuccess: () => {
      invalidateRentalsQuery();
      onSuccess();
    },
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submitPaymentMethod();
      }}
      className={classes.form}
    >
      <Stack h="100%" justify="space-between">
        <PaymentElement options={{ fields: { billingDetails: "never" } }} />

        <Group spacing="xs" className={classes.buttonsWrapper}>
          <Button variant="ghost" onClick={onCancel}>
            {t("rental.quit")}
          </Button>

          <Button
            type="submit"
            disabled={!stripe}
            loading={isSubmittingPaymentMethod}
            onClick={() => {
              trackClick(TrackingEventCategory.BUTTON, "click_update_payment");
            }}
          >
            {t("rental.save")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
