import { Center, Group, Stack } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { Button, TextInput } from "components";
import { useForm } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { requestCustomerEmailUpdateValidationCode } from "services/api/customer";
import { z } from "zod";
import { useStyles } from "./ProfileCustomerEmailUpdateForm.styles";

interface Props {
  customerId: string;
  initialEmail: string;
  setNewCustomerEmail: (newCustomerEmail: string) => void;
  setAttemptsLeft: (attemptsLeft: number) => void;
  closeModal: () => void;
}

export const ProfileCustomerEmailUpdateForm: React.FC<Props> = ({
  customerId,
  initialEmail,
  setNewCustomerEmail,
  setAttemptsLeft,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { getInputProps, onSubmit } = useForm({
    schema: z.object({
      email: z.string().email(),
    }),
    initialValues: { email: initialEmail },
  });

  const { mutate: requestValidationCode, isLoading: isRequestingValidationCode } = useMutation({
    mutationFn: requestCustomerEmailUpdateValidationCode,
    onSuccess: ({ attempts_left }, { email_to_validate }) => {
      setAttemptsLeft(attempts_left);
      setNewCustomerEmail(email_to_validate);
    },
  });

  return (
    <form
      noValidate
      className={classes.form}
      onSubmit={onSubmit(({ email }) =>
        requestValidationCode({ customerId, email_to_validate: email })
      )}
    >
      <Stack h="100%" justify="space-between">
        <Center className={classes.textInputWrapper}>
          <TextInput autoComplete="tel" {...getInputProps("email")} name="email" hideError />
        </Center>

        <Group spacing="xs" className={classes.buttonsWrapper}>
          <Button variant="ghost" onClick={closeModal}>
            {t("profile.quit")}
          </Button>

          <Button type="submit" loading={isRequestingValidationCode}>
            {t("profile.validate")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
