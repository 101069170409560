import { Anchor, Flex, Group, Text } from "@mantine/core";
import { Icon, IconArrowDown, IconCheck, IconClock, IconX } from "@tabler/icons-react";
import { Badge, BadgeIcon, BadgeState } from "components";
import { createDate, formatDate } from "helpers/date";
import { createPrice, formatPrice } from "helpers/price";
import { useSession } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { Invoice, InvoicePaymentStatus, RoutePath } from "types";
import { useStyles } from "./InvoiceDetails.styles";

interface Props {
  invoice: Invoice;
}

const badgeStateByInvoicePaymentStatus: Record<InvoicePaymentStatus, BadgeState> = {
  [InvoicePaymentStatus.FAILED]: "negative",
  [InvoicePaymentStatus.SUCCEEDED]: "positive",
  [InvoicePaymentStatus.PENDING]: "warning",
};

const iconByInvoicePaymentStatus: Record<InvoicePaymentStatus, Icon> = {
  [InvoicePaymentStatus.FAILED]: IconX,
  [InvoicePaymentStatus.SUCCEEDED]: IconCheck,
  [InvoicePaymentStatus.PENDING]: IconClock,
};

export const InvoiceDetails: React.FC<Props> = ({ invoice }) => {
  const { t } = useTranslation();
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();
  const session = useSession(String(invoice.session_id));
  const { pathname } = useLocation();
  const isOrderPage = !!matchPath({ path: RoutePath.ORDER }, pathname);

  const PaymentStatusIcon = useMemo(
    () => iconByInvoicePaymentStatus[invoice.payment_status],
    [invoice.payment_status]
  );

  return (
    <Group>
      <Group spacing="sm" align="center" className={classes.leftSection}>
        {!!invoice.file_url && (
          <a
            href={invoice.file_url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.invoiceFileLink}
            onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_download_invoice")}
          >
            <BadgeIcon icon={IconArrowDown} state="message" bg="transparent" withBorder />
          </a>
        )}

        <div>
          <Anchor
            href={invoice.file_url ?? undefined}
            td="underline"
            target="_blank"
            rel="noopener noreferrer"
            weight={700}
            onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_download_invoice")}
          >
            {t("invoicesList.invoiceLabel", {
              invoiceDate: formatDate(createDate(invoice.issuing_date)),
            })}
          </Anchor>

          <Text>{t(`invoicesList.invoiceTypes.${invoice.sline_invoice_type}`)}</Text>

          <Text>{invoice.number}</Text>

          {!!session && !isOrderPage && (
            <Text>
              {t("invoicesList.invoiceOrderLabel", { sessionReference: session.reference })}
            </Text>
          )}
        </div>
      </Group>

      <Flex className={classes.rightSection}>
        <Badge
          state={badgeStateByInvoicePaymentStatus[invoice.payment_status]}
          className={classes.paymentStatusBadge}
        >
          {t(`invoicesList.invoicePaymentStatuses.${invoice.payment_status}`)}
        </Badge>

        <BadgeIcon
          icon={PaymentStatusIcon}
          state={badgeStateByInvoicePaymentStatus[invoice.payment_status]}
          size={24}
          className={classes.paymentStatusBadgeIcon}
        />

        <Text className={classes.price}>
          {formatPrice(createPrice(invoice.total_amount_cents))}
        </Text>
      </Flex>
    </Group>
  );
};
