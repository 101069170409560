import { useMemo } from "react";
import { useSessionsQuery } from "./useSessionsQuery";

export const useSession = (sessionId: string) => {
  const { sessions, isLoading } = useSessionsQuery();

  const session = useMemo(() => {
    if (isLoading) return null;

    return sessions?.find((session) => session.id === sessionId);
  }, [isLoading, sessionId, sessions]);

  return session;
};
