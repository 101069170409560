import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  itemImage: {
    display: "none",
    alignSelf: "center",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "block",
    },
  },
}));
