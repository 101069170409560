import { useQuery } from "@tanstack/react-query";
import { getRetailer } from "services/api/retailer";

export const useRetailerQuery = (retailerId: string) => {
  const { data: retailer, ...queryResult } = useQuery({
    queryKey: ["retailer", retailerId],
    queryFn: () => getRetailer(retailerId),
    retry: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  return {
    retailer,
    ...queryResult,
  };
};
