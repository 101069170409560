import { Stack, Title } from "@mantine/core";
import { InvoicesList } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Session } from "types";
import { useStyles } from "./OrderInvoices.styles";

interface Props {
  session: Session;
}

export const OrderInvoices: React.FC<Props> = ({ session }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (session.invoices.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Title size="h6" className={classes.title}>
        {t("order.invoices")}
      </Title>

      <InvoicesList invoices={session.invoices} />
    </Stack>
  );
};
