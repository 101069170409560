import { Group, Paper, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmailSketch } from "resources/sketches/email.svg";
import { LIGHT_BLUE } from "theme";

export const EmailNotification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Paper radius="md" p="md" bg={LIGHT_BLUE}>
      <Group align="center">
        <EmailSketch height={40} width={40} style={{ flexShrink: 0 }} />
        <Text>{t("claim.youWillBeNotifiedByEmail")}</Text>
      </Group>
    </Paper>
  );
};
