import { IconCreditCard } from "@tabler/icons-react";
import { ReactComponent as AmexIcon } from "resources/icons/amex.svg";
import { ReactComponent as CreditCardIcon } from "resources/icons/credit-card.svg";
import { ReactComponent as MasterCardIcon } from "resources/icons/mastercard.svg";
import { ReactComponent as SepaIcon } from "resources/icons/sepa.svg";
import { ReactComponent as VisaIcon } from "resources/icons/visa.svg";
import { InvoicePaymentMethod, InvoicePaymentMethodBrand } from "types";

interface Props {
  paymentMethod: InvoicePaymentMethod;
  brand: InvoicePaymentMethodBrand | null;
}

export const PaymentMethodIcon = ({ paymentMethod, brand }: Props) => {
  switch (paymentMethod) {
    case InvoicePaymentMethod.CARD: {
      switch (brand) {
        case InvoicePaymentMethodBrand.AMEX:
          return <AmexIcon data-testid="amex-svg-icon" />;
        case InvoicePaymentMethodBrand.VISA:
          return <VisaIcon data-testid="visa-svg-icon" />;
        case InvoicePaymentMethodBrand.MASTERCARD:
          return <MasterCardIcon data-testid="mastercard-svg-icon" />;
        case InvoicePaymentMethodBrand.CB:
          return <CreditCardIcon data-testid="cb-svg-icon" />;
        default:
          return <IconCreditCard data-testid="creditcard-icon" />;
      }
    }
    case InvoicePaymentMethod.SEPA_DEBIT:
      return <SepaIcon data-testid="sepa-svg-icon" />;
  }
};
