import { Stack, Text, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { BadgeIcon, Button } from "components";
import { setCustomerQueryData } from "helpers/customer";
import React from "react";
import { useTranslation } from "react-i18next";
import { updateCustomer as _updateCustomer } from "services/api/customer";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Customer } from "types";
import { ProfileCustomerBillingAddressUpdateForm } from "./Form";

interface Props {
  customer: Customer;
  closeModal: () => void;
}

export const ProfileCustomerBillingAddressUpdate: React.FC<Props> = ({ customer, closeModal }) => {
  const { t } = useTranslation();

  const {
    mutate: updateCustomer,
    isLoading: isUpdatingCustomer,
    isSuccess: isCustomerUpdated,
  } = useMutation({
    mutationFn: _updateCustomer,
    onSuccess: (updatedCustomer) => setCustomerQueryData(updatedCustomer),
  });

  if (isCustomerUpdated)
    return (
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ flex: 1 }} justify="center" align="center" spacing="sm">
          <BadgeIcon icon={IconCheck} state="positive" size={rem(40)} />
          <Text>{t("profile.updatedSuccessfully")}</Text>
        </Stack>

        <Button
          sx={({ fn: { largerThan } }) => ({
            [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
          })}
          onClick={closeModal}
        >
          {t("profile.quit")}
        </Button>
      </Stack>
    );

  return (
    <Stack sx={{ flex: 1 }}>
      <Text>{t("profile.updateBillingAddressDescription")}</Text>

      <ProfileCustomerBillingAddressUpdateForm
        customer={customer}
        updateCustomer={updateCustomer}
        isUpdatingCustomer={isUpdatingCustomer}
        closeModal={closeModal}
      />
    </Stack>
  );
};
