import { Alert, Group, Text } from "@mantine/core";
import { createDate, formatDate } from "helpers/date";
import { createPrice, formatPrice, sumPrices } from "helpers/price";
import { pick } from "lodash";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { RED } from "theme";
import { InvoicePaymentStatus, Rental } from "types";
import { RentalUnpaidInvoicesRegularizeButton } from "./RegularizeButton";
import { useStyles } from "./RentalUnpaidInvoicesAlert.styles";
import animationData from "./animation.json";

interface Props {
  rental: Rental;
}

export const RentalUnpaidInvoicesAlert: React.FC<Props> = ({ rental }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const unpaidInvoices = useMemo(
    () =>
      rental.invoices.filter((invoice) => invoice.payment_status === InvoicePaymentStatus.FAILED),
    [rental.invoices]
  );

  const totalUnpaidPrice = useMemo(
    () => sumPrices(unpaidInvoices.map((invoice) => createPrice(invoice.total_amount_cents))),
    [unpaidInvoices]
  );

  const canRegularize = useMemo(() => {
    if (unpaidInvoices.length === 0) return false;

    const currentDate = new Date();

    return unpaidInvoices.some((invoice) => {
      const lastPaymentRetry = invoice.last_payment_retry;

      if (!lastPaymentRetry) return true;

      const lastPaymentRetryDate = createDate(lastPaymentRetry);

      return !(
        lastPaymentRetryDate.getFullYear() === currentDate.getFullYear() &&
        lastPaymentRetryDate.getMonth() === currentDate.getMonth() &&
        lastPaymentRetryDate.getDate() === currentDate.getDate()
      );
    });
  }, [unpaidInvoices]);

  const tomorrowDate = useMemo(() => {
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    return tomorrowDate;
  }, []);

  if (unpaidInvoices.length === 0) return null;

  return (
    <Alert color="red" py="xs" px="sm" classNames={pick(classes, "root", "message")}>
      <Group spacing="sm" position="center">
        <div className={classes.animationWrapper}>
          <Lottie options={{ loop: true, autoplay: true, animationData }} height={24} width={24} />
        </div>

        <Text size="md">
          <Trans
            i18nKey="rental.totalUnpaidAmount"
            values={{ amount: formatPrice(totalUnpaidPrice) }}
          />
        </Text>
      </Group>

      {canRegularize ? (
        <RentalUnpaidInvoicesRegularizeButton
          rental={rental}
          unpaidInvoices={unpaidInvoices}
          totalUnpaidPrice={totalUnpaidPrice}
        />
      ) : (
        <Text fw={700} size="sm" color={RED}>
          {t("rental.nextRegularizationDate", { date: formatDate(tomorrowDate) })}
        </Text>
      )}
    </Alert>
  );
};
