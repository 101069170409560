import { Paper, Stack, Text } from "@mantine/core";
import { createDate, formatDate } from "helpers/date";
import React from "react";
import { useTranslation } from "react-i18next";
import { Customer } from "types";

interface Props {
  customer: Customer;
}

export const ProfileCustomerBirth: React.FC<Props> = ({
  customer: { is_company, date_of_birth },
}) => {
  const { t } = useTranslation();

  if (is_company) return null;

  return (
    <Paper radius="md" withBorder p="sm">
      <Stack spacing="xs">
        <Text fw={700}>{t("profile.birth")}</Text>
        <Text>{formatDate(createDate(date_of_birth))}</Text>
      </Stack>
    </Paper>
  );
};
