import { DeliveryMethod, LineItemType, SessionDocumentType, SessionStatus } from "types";
import { z } from "zod";
import { invoiceSchema } from "./invoice";
import { rentalItemSchema } from "./rental";
import { enumFrom, nullableDefault } from "./utils";

export const lineItemPlanSchema = z.object({
  duration: z.number(),
  first_instalment_with_tax: z.number(),
  other_instalment_with_tax: z.number(),
});

export const lineItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  reference: z.string().nullable(),
  image: z.string().nullable(),
  quantity: z.number(),
  item_type: enumFrom(LineItemType),
  rent: z.boolean(),
  plans: z.array(lineItemPlanSchema),
  coverage_configuration_code: z.string().nullable(),
  serial_numbers: z.array(z.string()),
});

export const sessionAddressSchema = z.object({
  street_address: z.string(),
  street_address_2: nullableDefault(z.string(), ""),
  zip_code: z.string(),
  city: z.string(),
});

export const sessionRetailerInfosSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const sessionRentalSchema = z.object({
  id: z.string(),
  reference: z.string(),
  rental_items: z.array(rentalItemSchema),
});

export const sessionDocumentFileSchema = z.object({
  url: z.string(),
});

export const sessionDocumentSchema = z.object({
  id: z.string(),
  document_type: enumFrom(SessionDocumentType).nullable(),
  files: nullableDefault(z.array(sessionDocumentFileSchema), []),
});

export const sessionSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  reference: z.string(),
  retailer_infos: sessionRetailerInfosSchema,
  rental_conditions_url: z.string().nullable(),
  status: enumFrom(SessionStatus),
  selected_duration: z.number(),
  delivery_method: enumFrom(DeliveryMethod),
  line_items: z.array(lineItemSchema),
  shipping_address: sessionAddressSchema.nullable(),
  billing_address: sessionAddressSchema.nullable(),
  store_name: z.string().nullable(),
  rental: sessionRentalSchema.nullable(),
  invoices: z.array(invoiceSchema),
  documents: z.array(sessionDocumentSchema),
});
