import { createStyles } from "@mantine/core";
import { GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ headings, spacing, fn: { smallerThan, largerThan } }) => ({
  customerAvatar: {
    alignSelf: "center",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  customerLabel: {
    [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignSelf: "center",
    },

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      fontSize: headings.sizes.h6.fontSize,
      padding: `0 ${spacing.xs}`,
    },
  },

  sectionsWrapper: {
    [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
      marginTop: spacing.sm,
    },

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      rowGap: 0,
      borderTopStyle: "solid",
      borderTopWidth: 1,
      borderTopColor: GRAY,
    },

    "> div[data-with-border]": {
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        borderRadius: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: GRAY,
        padding: `${spacing.sm} ${spacing.xs}`,
      },
    },
  },
}));
