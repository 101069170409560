import { Pagination, Paper, Stack } from "@mantine/core";
import { chunk } from "lodash";
import React, { useMemo, useState } from "react";
import { Invoice } from "types";
import { InvoiceDetails } from "./InvoiceDetails";

const MAX_INVOICE_BY_PAGE = 5;

interface Props {
  invoices: Invoice[];
}

export const InvoicesList: React.FC<Props> = ({ invoices }) => {
  const [page, setPage] = useState(1);

  const paginatedInvoices = useMemo(() => chunk(invoices, MAX_INVOICE_BY_PAGE), [invoices]);

  return (
    <Paper withBorder p="sm" shadow="xs" radius="md">
      <Stack spacing="sm">
        {paginatedInvoices[page - 1].map((invoice) => (
          <InvoiceDetails invoice={invoice} key={invoice.id} />
        ))}

        {paginatedInvoices.length > 1 && (
          <Pagination
            value={page}
            onChange={setPage}
            total={paginatedInvoices.length}
            sx={{ alignSelf: "flex-end" }}
            radius="md"
            spacing="xs"
          />
        )}
      </Stack>
    </Paper>
  );
};
