import { Paper, SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { computeClaimFormattedId } from "helpers/claim";
import { createDate, formatDate } from "helpers/date";
import React from "react";
import { useTranslation } from "react-i18next";
import { BLUE, DARK_GRAY } from "theme";
import { Claim } from "types";

interface Props {
  claim: Claim;
}

export const ClaimDetails: React.FC<Props> = ({ claim }) => {
  const { t } = useTranslation();
  const { declaration_date, status, incident_date, incident_type, rental_item_infos } = claim;

  return (
    <Stack spacing="sm">
      <Title order={3} size="h6">
        {t("claim.details")}
      </Title>
      <Paper withBorder p="sm" radius="md">
        <SimpleGrid cols={2} verticalSpacing="sm" sx={{ alignItems: "center" }}>
          <Text color={DARK_GRAY}>{t("claim.status")}</Text>
          <Text weight={700} color={BLUE}>
            {t(`claim.claimStatuses.${status}`)}
          </Text>
          <Text color={DARK_GRAY}>{t("claim.formSending")}</Text>
          <Text weight={700}>{formatDate(createDate(declaration_date))}</Text>
          <Text color={DARK_GRAY}>{t("claim.incidentDate")}</Text>
          <Text weight={700}>{formatDate(createDate(incident_date))}</Text>
          <Text color={DARK_GRAY}>{t("claim.claimNumber")}</Text>
          <Text weight={700}>{computeClaimFormattedId(claim)}</Text>
          <Text color={DARK_GRAY}>{t("claim.incidentType")}</Text>
          <Text weight={700}>{t(`claim.incidentTypes.${incident_type}`)}</Text>
          {rental_item_infos.serial_number && (
            <>
              <Text color={DARK_GRAY}>{t("claim.itemSerialNumber")}</Text>
              <Text weight={700}>{rental_item_infos.serial_number}</Text>
            </>
          )}
        </SimpleGrid>
      </Paper>
    </Stack>
  );
};
