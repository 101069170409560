import { BadgeProps, Badge as MantineBadge } from "@mantine/core";
import React from "react";
import { BadgeState, useStyles } from "./Badge.styles";

type Props = Omit<
  import("@mantine/utils").PolymorphicComponentProps<"div", BadgeProps>,
  "variant" | "radius" | "color" | "classNames"
> & { state: BadgeState };

export const Badge = React.forwardRef<HTMLDivElement, Props>(({ state, ...props }, ref) => {
  const { classes } = useStyles({ state });

  return <MantineBadge ref={ref} variant="light" radius="md" classNames={classes} {...props} />;
});
Badge.displayName = "Badge";
