import { DatePickerInput, DatePickerInputProps } from "@mantine/dates";
import { createDate, toDateString } from "helpers/date";
import React, { useCallback, useMemo, useState } from "react";
import { useStyles } from "./DateStringPickerInput.styles";

type Props = Omit<
  DatePickerInputProps,
  "value" | "onChange" | "valueFormat" | "classNames" | "color"
> & {
  value: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
};

export const DateStringPickerInput = React.forwardRef<HTMLButtonElement, Props>(
  ({ value, onChange, onFocus, onBlur, error, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const { classes } = useStyles({
      focused,
      empty: !value,
      withLabel: !!props.label,
      error: !!error,
    });

    const valueFormat = useMemo(
      () =>
        new Intl.DateTimeFormat()
          .format(new Date(1900, 0, 2))
          .replace("1900", "YYYY")
          .replace("01", "MM")
          .replace("1", "M")
          .replace("02", "DD")
          .replace("2", "D"),
      []
    );
    const date = useMemo(() => (value ? createDate(value) : null), [value]);
    const setDate = useCallback((date: Date) => onChange(toDateString(date)), [onChange]);

    return (
      <DatePickerInput
        ref={ref}
        value={date}
        onChange={setDate}
        valueFormat={valueFormat}
        classNames={classes}
        onFocus={(event) => {
          setFocused(true);
          onFocus?.(event);
        }}
        onBlur={(event) => {
          setFocused(false);
          onBlur?.(event);
        }}
        error={error}
        {...props}
      />
    );
  }
);
DateStringPickerInput.displayName = "DateStringPickerInput";
