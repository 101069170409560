import { Group, Paper, Stack, Text, Title, rem } from "@mantine/core";
import { IconSparkles } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Button, TextInput } from "components";
import { useForm } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { mobilePhoneSchema } from "schemas";
import { TrackingEventCategory } from "services/analytics";
import { requestAuthCode as _requestAuthCode } from "services/api/customer";
import { DARK_VIOLET, LIGHT_VIOLET } from "theme";
import { CustomerCredential } from "types";
import { z } from "zod";

interface Props {
  setCustomerCredential: (customerCredential: CustomerCredential) => void;
  setAttemptsLeft: (attemptsLeft: number) => void;
}

export const LoginForm: React.FC<Props> = ({ setCustomerCredential, setAttemptsLeft }) => {
  const { t } = useTranslation();
  const { trackClick } = useEventTracking();

  const { onSubmit, getInputProps, setErrors } = useForm({
    schema: z.object({
      credential: z.string().nonempty(String(t("login.pleaseEnterYourEmailAddressOrPhoneNumber"))),
    }),
    initialValues: {
      credential: "",
    },
  });

  const { mutate: requestAuthCode, isLoading: isRequestingAuthCode } = useMutation({
    mutationFn: _requestAuthCode,
    onSuccess: ({ attempts_left }, customerCredential) => {
      setAttemptsLeft(attempts_left);
      setCustomerCredential(customerCredential);
    },
  });

  const submitAuthCredential = useCallback(
    (credential: string) => {
      // check if user's credential input is an email
      try {
        const email = z.string().email().parse(credential);
        void requestAuthCode({ email });
        return;
      } catch {
        /* */
      }

      // check if user's credential input is a phone
      try {
        const phone = mobilePhoneSchema().parse(credential);
        void requestAuthCode({ phone });
        return;
      } catch {
        /* */
      }

      // set an error on credential input
      setErrors({ credential: t("login.invalidEmailOrPhoneNumber") });
    },
    [requestAuthCode, setErrors, t]
  );

  return (
    <Stack align="center" w={450}>
      <Title size="h4" align="center">
        {t("login.myCustomerSpace")}
      </Title>
      <Title size="h5" align="center">
        {t("login.loginToCustomerSpaceMessage")}
      </Title>
      <form
        style={{ alignSelf: "stretch" }}
        onSubmit={onSubmit(({ credential }) => submitAuthCredential(credential))}
        noValidate
      >
        <Stack align="stretch">
          <TextInput
            label={t("login.enterYourEmailAddressOrPhoneNumber")}
            type="email"
            autoComplete="username"
            {...getInputProps("credential")}
            styles={{ input: { height: rem(50) } }}
            data-testid="login-credential-input"
          />
          <Button
            type="submit"
            loading={isRequestingAuthCode}
            data-testid="login-submit-button"
            onClick={() => trackClick(TrackingEventCategory.LINK, "click_log_in")}
          >
            {t("login.logIn")}
          </Button>
          <Paper bg={LIGHT_VIOLET} p="md">
            <Group>
              <IconSparkles color={DARK_VIOLET} size={28} style={{ flexShrink: 0 }} />
              <Text color={DARK_VIOLET} size="sm">
                {t("login.weWillSendYouAnAuthCode")}
              </Text>
            </Group>
          </Paper>
        </Stack>
      </form>
    </Stack>
  );
};
