import { invoicePaymentInfoSchema, invoiceSchema } from "schemas";
import { z } from "zod";

export enum InvoicePaymentStatus {
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export enum InvoiceType {
  FIRST_INVOICE = "first_invoice",
  INSTALMENT_INVOICE = "instalment_invoice",
  RETURN_INVOICE = "return_invoice",
  ADJUSTMENT_INVOICE = "adjustment_invoice",
}

export enum InvoicePaymentMethod {
  CARD = "card",
  SEPA_DEBIT = "sepa_debit",
}

export enum InvoicePaymentMethodBrand {
  AMEX = "amex",
  CB = "cartes_bancaires",
  DISCOVER = "discover",
  JCB = "jcb",
  MASTERCARD = "mastercard",
  VISA = "visa",
  UNIONPAY = "unionpay",
}

export type Invoice = z.infer<typeof invoiceSchema>;

export type InvoicePaymentInfo = z.infer<typeof invoicePaymentInfoSchema>;
