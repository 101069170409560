import { Stack, Title } from "@mantine/core";
import { useSessionsQuery } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import orderBy from "lodash/orderBy";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrderCard } from "./components";

export const OrdersPage: React.FC = () => {
  usePageViewTracking("sessions-list");
  const { sessions } = useSessionsQuery();
  const { t } = useTranslation();

  return (
    <Stack spacing="lg">
      <Title size="h3">{t("order.myOrders")}</Title>

      {!!sessions &&
        orderBy(sessions, "-created_at").map((session) => (
          <OrderCard key={session.id} session={session} />
        ))}
    </Stack>
  );
};
