import { IconBolt, IconHistory, IconListDetails, IconUser } from "@tabler/icons-react";
import { useClaimsQuery, useRentalsQuery, useRetailerQueries, useSessionsQuery } from "hooks";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import { RentalStatus, RoutePath } from "types";

export const useNavbarLinks = () => {
  const { rentals } = useRentalsQuery();
  const { sessions } = useSessionsQuery();
  const { claims } = useClaimsQuery();
  const activeRentalRetailerIds = rentals
    ? uniq(
        rentals
          .filter((rental) => rental.status === RentalStatus.ACTIVE)
          .map((rental) => rental.retailer_infos.id)
      )
    : [];

  const retailerQueries = useRetailerQueries(activeRentalRetailerIds);

  const navbarLinks = useMemo(() => {
    const navbarLinks = [
      {
        link: "rentals",
        path: RoutePath.RENTALS,
        icon: IconListDetails,
        count: rentals?.length,
      },
      {
        link: "orders",
        path: RoutePath.ORDERS,
        icon: IconHistory,
        count: sessions?.length,
      },
    ];

    if (retailerQueries.some(({ retailer }) => retailer?.activate_claims)) {
      navbarLinks.push({
        link: "claims",
        path: RoutePath.CLAIMS,
        icon: IconBolt,
        count: claims?.length,
      });
    }

    navbarLinks.push({
      link: "profile",
      path: RoutePath.PROFILE,
      icon: IconUser,
      count: undefined,
    });

    return navbarLinks;
  }, [claims?.length, rentals?.length, retailerQueries, sessions?.length]);

  return navbarLinks;
};
