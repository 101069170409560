import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ headings, fn: { smallerThan } }) => ({
  container: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flex: 1,
    },
  },

  title: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      fontSize: headings.sizes.h6.fontSize,

      "&[data-incident-type-selected='true']": {
        display: "none",
      },
    },
  },
}));
