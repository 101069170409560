import { Group, Stack } from "@mantine/core";
import { AddressAutocompleteInput, Button, TextInput } from "components";
import { useForm } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { customerAddressUpdateSchema } from "schemas";
import { Customer, CustomerUpdate } from "types";
import { useStyles } from "./ProfileCustomerBillingAddressUpdateForm.styles";

interface Props {
  customer: Customer;
  updateCustomer: (customer: CustomerUpdate) => void;
  isUpdatingCustomer: boolean;
  closeModal: () => void;
}

export const ProfileCustomerBillingAddressUpdateForm: React.FC<Props> = ({
  customer: { id: customerId, billing_address },
  updateCustomer,
  isUpdatingCustomer,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const initialBillingAddress = billing_address
    ? {
        streetAddress: billing_address.street_address,
        city: billing_address.city,
        zipCode: billing_address.zip_code,
        country: billing_address.country,
      }
    : undefined;

  const { getInputProps, setFieldValue, errors, onSubmit } = useForm({
    schema: customerAddressUpdateSchema,
    initialValues: {
      street_address: "",
      street_address_2: "",
      city: "",
      zip_code: "",
      country: "",
      ...billing_address,
    },
  });

  return (
    <form
      noValidate
      className={classes.form}
      onSubmit={onSubmit((billing_address) => updateCustomer({ id: customerId, billing_address }))}
    >
      <Stack h="100%" justify="space-between">
        <Stack spacing="sm">
          <AddressAutocompleteInput
            label={t("profile.address")}
            required
            initialAddress={initialBillingAddress}
            onChange={(address) => {
              setFieldValue("street_address", address?.streetAddress ?? "");
              setFieldValue("zip_code", address?.zipCode ?? "");
              setFieldValue("city", address?.city ?? "");
              setFieldValue("country", address?.country ?? "");
            }}
            error={!!errors["street_address"]}
            name="billing-address"
          />

          <TextInput
            label={t("profile.addressComplement")}
            {...getInputProps("street_address_2")}
            name="billing-address-complement"
          />
        </Stack>

        <Group spacing="xs" className={classes.buttonsWrapper}>
          <Button variant="ghost" onClick={closeModal}>
            {t("profile.quit")}
          </Button>

          <Button type="submit" loading={isUpdatingCustomer}>
            {t("profile.update")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
