import React, { PropsWithChildren } from "react";
import { AppContextProvider } from "./AppContext";
import { useClaimCreation, useHelpRequest } from "./hooks";

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const helpRequest = useHelpRequest();
  const claimCreation = useClaimCreation();

  return (
    <AppContextProvider
      value={{
        ...claimCreation,
        ...helpRequest,
      }}
    >
      {children}
    </AppContextProvider>
  );
};
