import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  col: {
    display: "flex",
    columnGap: spacing.xs,
    flexBasis: "50%",

    "> *:first-of-type": {
      flexShrink: 0,
    },

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      justifyContent: "space-between",
      flexBasis: "100%",

      "> *:last-child": {
        textAlign: "end",
      },
    },
  },
}));
