import { customerSchema } from "schemas";
import { CustomerUpdate } from "types";
import { apiClient } from "../client";

export const updateCustomer = async ({
  id: customerId,
  billing_address,
  shipping_address,
}: CustomerUpdate) =>
  customerSchema.parse(
    (
      await apiClient.patch(`v1/customers/${customerId}`, {
        customer: {
          billing_address_attributes: billing_address
            ? { ...billing_address, address_type: "billing" }
            : undefined,
          shipping_address_attributes: shipping_address
            ? { ...shipping_address, address_type: "shipping" }
            : undefined,
        },
      })
    ).data
  );
