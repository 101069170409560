import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan, largerThan } }) => ({
  form: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  },

  wrapper: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flex: 1,
      justifyContent: "space-between",
    },
  },

  submitButton: {
    [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignSelf: "flex-end",
    },
  },
}));
