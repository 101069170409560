import { Stack, Title } from "@mantine/core";
import { PaymentMethodDetails } from "components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Session } from "types";
import { useStyles } from "./OrderPaymentMethod.styles";

interface Props {
  session: Session;
}

export const OrderPaymentMethod: React.FC<Props> = ({ session }) => {
  const paymentInfo = useMemo(() => session.invoices.at(-1)?.payment_info, [session.invoices]);
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (!paymentInfo) return null;

  return (
    <Stack spacing="sm">
      <Title size="h6" className={classes.title}>
        {t("order.paymentMethod")}
      </Title>

      <PaymentMethodDetails paymentInfo={paymentInfo} />
    </Stack>
  );
};
