import { Anchor, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import { BadgeIcon } from "components";
import { useSessionsQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { Rental } from "types";
import { useStyles } from "./RentalLegalDocuments.styles";

interface Props {
  rental: Rental;
}

export const RentalLegalDocuments: React.FC<Props> = ({ rental }) => {
  const { t } = useTranslation();
  const { sessions } = useSessionsQuery();
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();

  const rentalSessions = useMemo(
    () => (sessions ? sessions.filter((session) => rental.sessions_ids.includes(session.id)) : []),
    [rental.sessions_ids, sessions]
  );

  if (!rentalSessions.some((session) => !!session.rental_conditions_url)) return null;

  return (
    <Stack spacing="sm">
      <Title size="h6" className={classes.title}>
        {t("rental.legal")}
      </Title>

      <Paper withBorder p="sm" shadow="xs" radius="md">
        <Stack spacing="sm">
          <Text weight={700}>{t("rental.generalConditionsOfRental")}</Text>

          {rentalSessions.map((session) =>
            session.rental_conditions_url ? (
              <Anchor
                key={session.id}
                href={session.rental_conditions_url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.fileLink}
                onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_download_CGL")}
              >
                <Group spacing="xs">
                  <BadgeIcon icon={IconArrowDown} state="message" bg="transparent" withBorder />

                  <Text span>
                    {t("rental.retailerGeneralConditionsOfRental", {
                      retailerName: session.retailer_infos.name,
                      sessionReference: session.reference,
                    })}
                  </Text>
                </Group>
              </Anchor>
            ) : null
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};
