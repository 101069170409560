import { z } from "zod";
import { nullableDefault } from "./utils";

export const customerAddressSchema = z.object({
  street_address: nullableDefault(z.string(), ""),
  street_address_2: nullableDefault(z.string(), ""),
  zip_code: nullableDefault(z.string(), ""),
  city: nullableDefault(z.string(), ""),
  country: nullableDefault(z.string(), ""),
});

export const customerAddressUpdateSchema = z.object({
  street_address: z.string().nonempty(),
  street_address_2: z.string(),
  zip_code: z.string().nonempty(),
  city: z.string().nonempty(),
  country: z.string().nonempty(),
});

export const customerSchema = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  phone: nullableDefault(z.string(), ""),
  date_of_birth: z.string(),
  billing_address: customerAddressSchema.nullable(),
  shipping_address: customerAddressSchema.nullable(),
  is_company: z.boolean(),
  company_name: z.string().nullable(),
  company_registration_id: z.string().nullable(),
  company_vat_id: z.string().nullable(),
});
