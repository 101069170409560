import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "contexts/AuthContext";
import { getCustomer } from "services/api/customer";

export const useCustomerQuery = () => {
  const { customerId } = useAuthContext();

  const { data: customer, ...queryResult } = useQuery({
    queryKey: ["customer", String(customerId)],
    queryFn: () => getCustomer(String(customerId)),
    retry: false,
    retryOnMount: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: !!customerId,
  });

  return {
    customer,
    ...queryResult,
  };
};
