import { Center, Group, Stack, Text, Title } from "@mantine/core";
import { Button, Header } from "components";
import { useViewportHeight } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as ErrorSketch } from "resources/sketches/error.svg";
import { RoutePath } from "types";
import { useStyles } from "./ErrorPage.styles";

export const ErrorPage: React.FC = () => {
  usePageViewTracking("error");

  const { t } = useTranslation();
  const viewportHeight = useViewportHeight();
  const { classes } = useStyles();

  return (
    <>
      <Header />
      <Stack spacing="xl" justify="space-between" mih={viewportHeight}>
        <Center py="4rem" px="sm">
          <Stack align="center" maw={800} spacing="lg">
            <Group spacing="lg" align="start">
              <Stack spacing="lg" className={classes.container}>
                <Title size="h3">{t(`error.title`)}</Title>
                <Text>{t(`error.message`)}</Text>
                <Link to={RoutePath.RENTALS}>
                  <Button w="100%">{t("error.accessCustomerSpace")}</Button>
                </Link>
              </Stack>
              <ErrorSketch className={classes.sketch} />
            </Group>
          </Stack>
        </Center>
      </Stack>
    </>
  );
};
