import { rentalItemSchema, rentalSchema } from "schemas";
import { z } from "zod";

export type RentalItem = z.infer<typeof rentalItemSchema>;

export enum RentalStatus {
  CREATED = "created",
  PENDING_DOCUMENTS = "pending_documents",
  PENDING_REVIEW = "pending_review",
  PENDING_E_SIGNATURE = "pending_e_signature",
  REVIEWED = "reviewed",
  CANCELED = "canceled",
  REJECTED = "rejected",
  ACTIVE = "active",
  DEBT_RECOVERY = "debt_recovery",
  CLOSED = "closed",
}

export type Rental = z.infer<typeof rentalSchema>;
