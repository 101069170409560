import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fontSizes, fn: { smallerThan } }) => ({
  title: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      fontSize: fontSizes.md,
    },
  },
}));
