import { Anchor, Group, Footer as MantineFooter, Text } from "@mantine/core";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { useStyles } from "./Footer.styles";

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { trackClick } = useEventTracking();

  return (
    <MantineFooter
      height="auto"
      withBorder={false}
      fixed={false}
      position={{ top: 0, left: 0 }}
      className={classes.footer}
    >
      <Group spacing="lg">
        <Anchor
          size="sm"
          weight={400}
          href="https://www.sline.io/conditions-generales-dutilisation"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick(TrackingEventCategory.LINK, "click_see_CGU")}
        >
          {t("footer.generalConditionsOfUse")}
        </Anchor>

        <Anchor
          size="sm"
          weight={400}
          href="https://www.sline.io/politique-de-confidentialite"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick(TrackingEventCategory.LINK, "click_see_confidentiality")}
        >
          {t("footer.privacyPolicy")}
        </Anchor>
      </Group>

      <Text weight={700} size="sm">
        © Sline.io {new Date().getFullYear()}
      </Text>
    </MantineFooter>
  );
};
