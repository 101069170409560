import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

interface Params {
  hasUnpaidInvoices: boolean;
}

export const useStyles = createStyles<string, Params>(
  ({ spacing, fn: { smallerThan } }, { hasUnpaidInvoices }) => ({
    backToRentalsLink: {
      display: "block",
      marginBottom: spacing.lg,
    },

    rentalHeader: {
      alignItems: "flex-end",
      columnGap: spacing.md,
      rowGap: spacing.xs,

      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        flexDirection: "column-reverse",
        alignItems: "flex-start",
      },
    },

    panel: {
      marginTop: spacing.lg,

      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        display: "block",
      },
    },

    tabsList: {
      marginTop: spacing.md,

      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },

    panelWrapper: {
      flexDirection: hasUnpaidInvoices ? "column-reverse" : "column",
    },
  })
);
