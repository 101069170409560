import { Stack, Title } from "@mantine/core";
import { InvoicesList } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Rental } from "types";
import { useStyles } from "./RentalInvoices.styles";
import { RentalUnpaidInvoicesAlert } from "./UnpaidAlert/RentalUnpaidInvoicesAlert";

interface Props {
  rental: Rental;
}

export const RentalInvoices: React.FC<Props> = ({ rental }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (rental.invoices.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Title size="h6" className={classes.title}>
        {t("rental.invoices")}
      </Title>

      <RentalUnpaidInvoicesAlert rental={rental} />

      <InvoicesList invoices={rental.invoices} />
    </Stack>
  );
};
