import { Paper, Stack, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  description: string;
}

export const ClaimIncidentDescription: React.FC<Props> = ({ description }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="sm">
      <Title order={3} size="h6">
        {t("claim.incidentDescription")}
      </Title>
      <Paper withBorder p="sm" radius="md">
        {description}
      </Paper>
    </Stack>
  );
};
