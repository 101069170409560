import { CustomerEmailUpdateRequest, CustomerPhoneUpdateRequest } from "types";
import { z } from "zod";
import { apiClient } from "../client";

export const requestCustomerPhoneUpdateValidationCode = async ({
  customerId,
  ...customer
}: CustomerPhoneUpdateRequest) =>
  z
    .object({ attempts_left: z.number() })
    .parse(
      (await apiClient.post(`v1/customers/${customerId}/request_validation_code`, { customer }))
        .data
    );

export const requestCustomerEmailUpdateValidationCode = async ({
  customerId,
  ...customer
}: CustomerEmailUpdateRequest) =>
  z
    .object({ attempts_left: z.number() })
    .parse(
      (await apiClient.post(`v1/customers/${customerId}/request_validation_code`, { customer }))
        .data
    );
