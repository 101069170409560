import { Anchor, Flex, Group, Stack, Text, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { sendRetailerHelpRequest as _sendRetailerHelpRequest } from "services/api/retailer";
import { HelpRequestForm } from "./Form";
import { useStyles } from "./HelpRequest.styles";
import { HelpRequestSuccess } from "./HelpRequestSuccess";

interface Props {
  close: () => void;
}

export const HelpRequest: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    mutate: sendHelpRequest,
    isLoading: isSendingHelpRequest,
    isSuccess: isHelpRequestSent,
  } = useMutation(_sendRetailerHelpRequest);

  if (isHelpRequestSent) return <HelpRequestSuccess close={close} />;

  return (
    <Stack spacing="lg" className={classes.container}>
      <Anchor component="div" onClick={close}>
        <Group spacing="xs">
          <IconArrowLeft />
          <Text weight={700}>{t("help.backToPreviousPage")}</Text>
        </Group>
      </Anchor>

      <Flex direction="column" className={classes.formWrapper}>
        <Title size="h4" className={classes.title}>
          {t("help.howCanWeHelp")}
        </Title>

        <HelpRequestForm
          sendHelpRequest={sendHelpRequest}
          isSendingHelpRequest={isSendingHelpRequest}
        />
      </Flex>
    </Stack>
  );
};
