import { Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import { setCustomerQueryData } from "helpers/customer";
import { useCustomerQuery, useRentalsQuery, useReturnQueries } from "hooks";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { updateCustomer as _updateCustomer } from "services/api/customer";
import { ReturnDetails } from "./ReturnDetails";
import { ReturnForm } from "./ReturnForm";
import { ReturnSuccess } from "./ReturnSuccess";

export const ReturnModal: React.FC = () => {
  const [opened, { open, close }] = useDisclosure();
  const { rentals } = useRentalsQuery();
  const { customer } = useCustomerQuery();
  const { t } = useTranslation();

  const returnsIds = useMemo(
    () =>
      rentals
        ? rentals.flatMap((rental) =>
            rental.rental_items
              .filter((item) => !!item.return_id)
              .map((item) => item.return_id as string)
          )
        : [],
    [rentals]
  );

  const returnsQueries = useReturnQueries(returnsIds);

  const returnRentals = useMemo(() => {
    if (!rentals || returnsQueries.some((query) => query.isLoading)) return [];

    const createdReturnIds = returnsQueries
      .filter((query) => !!query.return && query.return.status === "created")
      .map((query) => query.return?.id as string);

    return rentals.filter((rental) =>
      rental.rental_items.some((item) =>
        item.return_id ? createdReturnIds.includes(item.return_id) : false
      )
    );
  }, [rentals, returnsQueries]);

  const {
    mutate: updateCustomer,
    isSuccess: isCustomerUpdated,
    isLoading: isUpdatingCustomer,
  } = useMutation({
    mutationFn: _updateCustomer,
    onSuccess: (updatedCustomer) => setCustomerQueryData(updatedCustomer),
  });

  useEffect(() => {
    if (returnRentals.length > 0 && !!customer && !opened && !isCustomerUpdated) {
      open();
    }
  }, [customer, isCustomerUpdated, open, opened, returnRentals.length]);

  if (!customer) return null;

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={t("return.endingRentalCount", { count: returnRentals.length })}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      {isCustomerUpdated ? (
        <ReturnSuccess returnRentals={returnRentals} close={close} />
      ) : (
        <Stack sx={{ flex: 1 }}>
          <ReturnDetails returnRentals={returnRentals} />

          <Text>{t("return.confirmAddressDescription")}</Text>

          <ReturnForm
            customer={customer}
            updateCustomer={updateCustomer}
            isUpdatingCustomer={isUpdatingCustomer}
          />
        </Stack>
      )}
    </Modal>
  );
};
