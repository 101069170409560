import { lineItemSchema, sessionSchema } from "schemas";
import { z } from "zod";

export enum DeliveryMethod {
  HOME_DELIVERY = "home_delivery",
  STORE_PICKUP = "store_pickup",
}

export enum LineItemType {
  PHYSICAL = "physical",
  SHIPPING = "shipping",
  COUPON = "coupon",
}

export enum SessionStatus {
  CREATED = "created",
  ID_VERIFIED = "id_verified",
  ACTIVE = "active",
  PREPARING_PAYMENT = "preparing_payment",
  WEB_SCORING_REJECTED = "web_scoring_rejected",
  OPEN_BANKING_SCORING_REJECTED = "open_banking_scoring_rejected",
  BLOCKED = "blocked",
  PENDING_DOCUMENTS = "pending_documents",
  PENDING_E_SIGNATURE = "pending_e_signature",
  PENDING_REVIEW = "pending_review",
  REVIEWED = "reviewed",
  CANCELED = "canceled",
  REJECTED = "rejected",
  EXPIRED = "expired",
  COMPLETE = "complete",
  CALF_REJECTED = "calf_rejected",
}

export enum SessionDocumentType {
  IDENTITY = "identity",
  TAX = "tax",
  SALARY = "salary",
  CORPORATION_CERTIFICATE = "corporation_certificate",
  CALF_CONTRACT_SIGNED = "calf_contract_signed",
  BANK = "bank",
}

export type LineItem = z.infer<typeof lineItemSchema>;

export type Session = z.infer<typeof sessionSchema>;
