import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Button } from "components";
import { formatPrice } from "helpers/price";
import React from "react";
import { useTranslation } from "react-i18next";
import { RED, SMALL_SCREEN_BREAKPOINT } from "theme";
import { Invoice, Rental } from "types";
import { RentalUnpaidInvoicesRegularization } from "./RentalUnpaidInvoicesRegularization";

interface Props {
  rental: Rental;
  unpaidInvoices: Invoice[];
  totalUnpaidPrice: Dinero.Dinero;
}

export const RentalUnpaidInvoicesRegularizeButton: React.FC<Props> = ({
  rental,
  unpaidInvoices,
  totalUnpaidPrice,
}) => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        opened={modalOpened}
        onClose={closeModal}
        title={t("rental.regularizationAmount", { amount: formatPrice(totalUnpaidPrice) })}
      >
        <RentalUnpaidInvoicesRegularization
          rental={rental}
          unpaidInvoices={unpaidInvoices}
          totalUnpaidPrice={totalUnpaidPrice}
          closeModal={closeModal}
        />
      </Modal>

      <Button
        color={RED}
        variant="secondary"
        sx={({ fn: { smallerThan } }) => ({
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "stretch" },
        })}
        onClick={openModal}
      >
        {t("rental.regularizeSituation")}
      </Button>
    </>
  );
};
