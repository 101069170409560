import { createStyles } from "@mantine/core";
import { LIGHT_BLUE, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan }, shadows }) => ({
  card: {
    backgroundColor: LIGHT_BLUE,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      boxShadow: shadows.xl,
      cursor: "pointer",
    },
  },

  container: {
    padding: spacing.md,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      padding: spacing.sm,

      " > *": {
        flex: 1,
      },
    },
  },

  leftSection: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flexDirection: "column-reverse",
    },
  },

  rightSection: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  sessionStatusBadgeWrapper: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignSelf: "flex-end",
    },
  },
}));
