import { Stack } from "@mantine/core";
import React, { useMemo } from "react";
import { Session } from "types";
import { OrderDetailsCard } from "./Card";
import { OrderPurchasedItemsList } from "./OrderPurchasedItemsList";
import { OrderRentedItemsList } from "./OrderRentedItemsList";
import { buildOrderItems } from "./helpers";

interface Props {
  session: Session;
}

export const OrderDetails: React.FC<Props> = ({ session }) => {
  const orderItems = useMemo(
    () => buildOrderItems(session.line_items, session.selected_duration),
    [session.line_items, session.selected_duration]
  );

  return (
    <Stack spacing="lg">
      <OrderDetailsCard session={session} orderItems={orderItems} />
      <OrderRentedItemsList session={session} orderItems={orderItems} />
      <OrderPurchasedItemsList session={session} orderItems={orderItems} />
    </Stack>
  );
};
