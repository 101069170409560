import { Group, Stack, Title } from "@mantine/core";
import { PaymentMethodDetails } from "components";
import { getRentalPaymentInfo } from "helpers/rental";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Rental } from "types";
import { useStyles } from "./RentalPaymentMethod.styles";
import { RentalPaymentMethodUpdateButton } from "./UpdateButton/RentalPaymentMethodUpdateButton";

interface Props {
  rental: Rental;
}

export const RentalPaymentMethod: React.FC<Props> = ({ rental }) => {
  const paymentInfo = useMemo(() => getRentalPaymentInfo(rental), [rental]);
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (!paymentInfo) return null;

  return (
    <Stack spacing="sm">
      <Group spacing="sm">
        <Title size="h6" className={classes.title}>
          {t("rental.paymentMethod")}
        </Title>

        <RentalPaymentMethodUpdateButton rental={rental} />
      </Group>

      <PaymentMethodDetails paymentInfo={paymentInfo} />
    </Stack>
  );
};
