import { retailerCoverageConfigurationSchema, retailerSchema } from "schemas";
import { z } from "zod";

export enum CoverageType {
  BREAKDOWN = "breakdown",
  BREAKAGE = "breakage",
  THEFT = "theft",
  LOSS = "loss",
}

export enum CoverageOwner {
  MERCHANT = "merchant",
  INSURER = "insurer",
  CUSTOMER = "customer",
}

export enum FinancingMode {
  AUTO = "auto",
  CACF = "cacf",
  CALF = "calf",
}

export type RetailerCoverageConfiguration = z.infer<typeof retailerCoverageConfigurationSchema>;

export type Retailer = z.infer<typeof retailerSchema>;

export type RetailerHelpRequest = {
  retailer_id: string;
  rental_id?: string;
  session_id?: string;
  message: string;
};
