import { Stack } from "@mantine/core";
import React from "react";
import { Session } from "types";
import { OrderBillingAddress } from "./BillingAddress";
import { OrderInvoices } from "./Invoices";
import { OrderLegalDocuments } from "./LegalDocuments";
import { OrderPaymentMethod } from "./PaymentMethod";

interface Props {
  session: Session;
}

export const OrderBilling: React.FC<Props> = ({ session }) => (
  <Stack spacing="lg">
    <OrderInvoices session={session} />
    <OrderBillingAddress session={session} />
    <OrderPaymentMethod session={session} />
    <OrderLegalDocuments session={session} />
  </Stack>
);
