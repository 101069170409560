import parseMobilePhone from "libphonenumber-js/mobile";
import { z } from "zod";

export const mobilePhoneSchema = () =>
  z.string().refine((value) => parseMobilePhone(value, "FR")?.isValid());

export const nullableDefault = <Schema extends z.Schema, Value>(
  schema: Schema,
  defaultValue: Value
) => schema.nullable().transform((value) => (value !== null ? value : defaultValue));

export const enumFrom = <T extends string>(e: { [s: string]: T }) =>
  z.enum(Object.values(e) as [T, ...T[]]);
