import { AppShell, MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { ModalsProvider } from "@mantine/modals";
import { QueryClientProvider } from "@tanstack/react-query";
import { AppProvider } from "contexts/AppContext";
import { AuthProvider } from "contexts/AuthContext";
import { MatomoProvider } from "contexts/MatomoContext";
import "dayjs/locale/fr";
import { useNetworkNotification, useSentry, useViewportHeight } from "hooks";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { useStyles } from "./App.styles";
import { AppRoutes } from "./AppRoutes";
import { ErrorBoundary, Footer, Header, Notifications } from "./components";
import { queryClient } from "./services/api";
import { mantineTheme } from "./theme";
import "./translations/i18n";

const App: React.FC = () => {
  useSentry();
  useNetworkNotification();
  const viewportHeight = useViewportHeight();
  const { classes } = useStyles({ viewportHeight });

  return (
    <MantineProvider theme={mantineTheme} withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        <DatesProvider settings={{ locale: "fr" }}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider>
                <AppProvider>
                  <MatomoProvider>
                    <ErrorBoundary>
                      <Notifications />
                      <AppShell
                        header={<Header />}
                        footer={<Footer />}
                        classNames={classes}
                        padding={0}
                      >
                        <AppRoutes />
                      </AppShell>
                    </ErrorBoundary>
                  </MatomoProvider>
                </AppProvider>
              </AuthProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </DatesProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};
export default App;
