import { useQueryClient } from "@tanstack/react-query";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { removeApiClientToken, setApiClientToken } from "services/api";
import { RoutePath } from "types";
import { AuthContextProvider } from "./AuthContext";

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [customerId, setCustomerId] = useState(sessionStorage.getItem("customer-id"));
  const queryClient = useQueryClient();
  const location = useLocation();

  const navigate = useNavigate();

  const logIn = useCallback(
    (customerId: string, token: string) => {
      sessionStorage.setItem("customer-id", customerId);
      sessionStorage.setItem("customer-token", token);

      setCustomerId(customerId);
      setApiClientToken(token);

      navigate(location.state?.from?.pathname || RoutePath.RENTALS, { replace: true });
    },
    [location.state?.from?.pathname, navigate, setCustomerId]
  );

  const logOut = useCallback(() => {
    sessionStorage.removeItem("customer-id");
    sessionStorage.removeItem("customer-token");

    setCustomerId(null);
    removeApiClientToken();

    navigate(RoutePath.LOGIN, { replace: true });

    queryClient.clear();
  }, [navigate, queryClient, setCustomerId]);

  return (
    <AuthContextProvider
      value={{
        customerId,
        logIn,
        logOut,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
