import { Stack } from "@mantine/core";
import React from "react";
import { Rental } from "types";
import { RentalCreditNotesList } from "./CreditNotesList";
import { RentalInvoices } from "./Invoices";
import { RentalLegalDocuments } from "./LegalDocuments";
import { RentalPaymentMethod } from "./PaymentMethod";

interface Props {
  rental: Rental;
}

export const RentalBilling: React.FC<Props> = ({ rental }) => (
  <Stack spacing="lg">
    <RentalInvoices rental={rental} />
    <RentalCreditNotesList rental={rental} />
    <RentalPaymentMethod rental={rental} />
    <RentalLegalDocuments rental={rental} />
  </Stack>
);
