import { Box, Global, Stack, Text, getStylesRef } from "@mantine/core";
import { Button } from "components";
import { useAppContext } from "contexts/AppContext";
import { useEventTracking } from "hooks/analytics";
import React, { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { RoutePath } from "types";
import { SMALL_SCREEN_NAVBAR_HEIGHT, useStyles } from "./AppLayout.styles";
import { ClaimCreation } from "./ClaimCreation";
import { HelpRequest } from "./HelpRequest";
import { ReturnModal } from "./ReturnModal";
import { useNavbarLinks } from "./hooks";

export const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { trackClick } = useEventTracking();
  const {
    openedHelpRequest,
    openHelpRequest,
    closeHelpRequest,

    openedClaimCreation,
    closeClaimCreation,
    rentalItemClaimCreation,
    retailerIdClaimCreation,
    onClaimCreated,
  } = useAppContext();

  const navbarLinks = useNavbarLinks();

  const isRentalPage = !!matchPath({ path: RoutePath.RENTAL }, pathname);
  const isOrderPage = !!matchPath({ path: RoutePath.ORDER }, pathname);

  const { classes, cx } = useStyles({
    helpRequestOpened: openedHelpRequest,
    claimCreationOpened: openedClaimCreation,
  });

  const mainContent = useMemo(() => {
    if (openedHelpRequest) return <HelpRequest close={closeHelpRequest} />;
    if (openedClaimCreation && rentalItemClaimCreation && retailerIdClaimCreation)
      return (
        <ClaimCreation
          rentalItem={rentalItemClaimCreation}
          retailerId={retailerIdClaimCreation}
          close={closeClaimCreation}
          onClaimCreated={onClaimCreated}
        />
      );

    return children;
  }, [
    openedHelpRequest,
    closeHelpRequest,
    openedClaimCreation,
    rentalItemClaimCreation,
    retailerIdClaimCreation,
    closeClaimCreation,
    onClaimCreated,
    children,
  ]);

  return (
    <>
      <Global
        styles={({ spacing }) => ({
          [`.${getStylesRef("notifications")}`]: {
            bottom: `calc(${SMALL_SCREEN_NAVBAR_HEIGHT}px + ${spacing.sm}) !important`,
          },
        })}
      />

      <ReturnModal />

      <Box className={classes.root}>
        <nav className={classes.navbar}>
          {navbarLinks.map(({ link, path, icon: Icon, count }) => (
            <div
              key={link}
              className={cx(classes.link, {
                [classes.activeLink]: pathname.startsWith(path),
              })}
              onClick={() => {
                closeHelpRequest();
                closeClaimCreation(() => navigate(path));
              }}
            >
              <Icon className={classes.linkIcon} />
              <Text>
                {t(`navbar.${link}`)}
                {count !== undefined ? (
                  <Text span className={classes.linkCount}>{` (${count})`}</Text>
                ) : null}
              </Text>
            </div>
          ))}
        </nav>

        <div className={classes.space} />

        <Box className={classes.main}>{mainContent}</Box>

        <div className={classes.space} />

        <div className={classes.aside}>
          {(isRentalPage || isOrderPage) && !openedHelpRequest && (
            <Box p="sm" className={classes.helpBox}>
              <Stack spacing="xs">
                <Text weight={700} size="lg">
                  {t("help.needHelp")}
                </Text>
                <Text>{t("help.helpMessage")}</Text>
                <Button
                  w="100%"
                  onClick={() => {
                    closeClaimCreation(openHelpRequest);
                    trackClick(TrackingEventCategory.LINK, "click_contact");
                  }}
                  variant="ghost"
                >
                  {t("help.contactUs")}
                </Button>
              </Stack>
            </Box>
          )}
        </div>
      </Box>
    </>
  );
};
