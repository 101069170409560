import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(
  ({ spacing, fontSizes, fn: { smallerThan, largerThan } }) => ({
    container: {
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        flex: 1,
      },
    },

    title: {
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        fontSize: fontSizes.md,
      },

      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        marginBottom: spacing.md,
      },
    },

    formWrapper: {
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        flex: 1,
      },
    },
  })
);
