import { Group, Stack, Text, rem } from "@mantine/core";
import { Icon, IconCheck, IconHourglass, IconX } from "@tabler/icons-react";
import { BadgeIcon, BadgeState, Button } from "components";
import { formatDate } from "helpers/date";
import { formatPrice } from "helpers/price";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export enum RegularizationStatus {
  SUCCESS = "success",
  PENDING = "pending",
  FAILED = "failed",
  INCOMPLETE = "incomplete",
}

const BADGE_OPTIONS_BY_REGULARIZATION_STATUS: Record<
  RegularizationStatus,
  { icon: Icon; state: BadgeState }
> = {
  [RegularizationStatus.SUCCESS]: {
    icon: IconCheck,
    state: "positive",
  },
  [RegularizationStatus.PENDING]: {
    icon: IconHourglass,
    state: "message",
  },
  [RegularizationStatus.FAILED]: {
    icon: IconX,
    state: "negative",
  },
  [RegularizationStatus.INCOMPLETE]: {
    icon: IconX,
    state: "negative",
  },
};

interface Props {
  regularizationStatus: RegularizationStatus;
  totalUnpaidPrice: Dinero.Dinero;
  closeModal: () => void;
}

export const RentalUnpaidInvoicesRegularizationStatus: React.FC<Props> = ({
  regularizationStatus,
  totalUnpaidPrice,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { icon, state } = BADGE_OPTIONS_BY_REGULARIZATION_STATUS[regularizationStatus];

  return (
    <Stack sx={{ flex: 1 }} justify="space-between">
      <Group
        align="center"
        sx={({ fn: { smallerThan } }) => ({
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: { flexDirection: "column" },
        })}
      >
        <BadgeIcon icon={icon} state={state} size={rem(40)} sx={{ alignSelf: "center" }} />

        <Text>
          <Trans
            i18nKey={`rental.regularizationStatuses.${regularizationStatus}`}
            values={{ amount: formatPrice(totalUnpaidPrice), date: formatDate(new Date()) }}
          />
        </Text>
      </Group>

      <Button
        sx={({ fn: { largerThan } }) => ({
          [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
        })}
        onClick={closeModal}
      >
        {t("rental.quit")}
      </Button>
    </Stack>
  );
};
