import { Paper, Stack, Text } from "@mantine/core";
import { createDate, formatDate } from "helpers/date";
import React from "react";
import { useTranslation } from "react-i18next";
import { Customer } from "types";

interface Props {
  customer: Customer;
}

export const ProfileCustomerCompanyContact: React.FC<Props> = ({
  customer: { is_company, first_name, last_name, date_of_birth },
}) => {
  const { t } = useTranslation();

  if (!is_company) return null;

  return (
    <Paper radius="md" withBorder p="sm">
      <Stack spacing="xs">
        <Text fw={700}>{t("profile.contact")}</Text>
        <Text>
          {first_name} {last_name}
        </Text>
        {date_of_birth && <Text>{formatDate(createDate(date_of_birth))}</Text>}
      </Stack>
    </Paper>
  );
};
