import { Group, Stack, Text } from "@mantine/core";
import { createPrice, formatPrice, sumPrices } from "helpers/price";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Session } from "types";
import { OrderItemCard } from "./ItemCard";
import { OrderItem } from "./helpers";

interface Props {
  session: Session;
  orderItems: OrderItem[];
}

export const OrderRentedItemsList: React.FC<Props> = ({ session, orderItems }) => {
  const { t } = useTranslation();

  const rentedItems = useMemo(() => {
    const sessionRentalItems = (session.rental?.rental_items ?? [])
      .filter((rentalItem) => rentalItem.session_id === session.id)
      .map((rentalItem) => ({
        ...rentalItem,
        coverage_configuration_code: rentalItem.coverage_configuration?.code ?? null,
      }));

    if (sessionRentalItems.length > 0) return sessionRentalItems;

    return orderItems.filter((orderItem) => orderItem.rent);
  }, [orderItems, session.id, session.rental?.rental_items]);

  const otherInstalmentWithTaxTotalPrice = useMemo(
    () => sumPrices(rentedItems.map((item) => createPrice(item.other_instalment_with_tax))),
    [rentedItems]
  );

  return (
    <Stack spacing="sm">
      <Group position="apart">
        <Text weight={700}>{t("order.rentedItemsCount", { count: rentedItems.length })}</Text>

        <Text weight={700}>
          {t("order.priceByMonth", {
            price: formatPrice(otherInstalmentWithTaxTotalPrice),
          })}
        </Text>
      </Group>

      {rentedItems.map((rentedItem) => (
        <OrderItemCard
          key={rentedItem.id}
          orderItem={rentedItem}
          retailerId={session.retailer_infos.id}
        />
      ))}
    </Stack>
  );
};
