import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan }, colors, spacing }) => ({
  container: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.gray[4],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: spacing.lg,
    rowGap: spacing.lg,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      borderStyle: "none",
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
    },
  },

  textWrapper: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignItems: "center",
      rowGap: spacing.xs,
    },
  },
}));
