import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "contexts/AuthContext";
import { getClaims } from "services/api/claim";

export const useClaimsQuery = () => {
  const { customerId } = useAuthContext();

  const { data: claims, ...queryResult } = useQuery({
    queryKey: ["claims"],
    queryFn: getClaims,
    retry: false,
    retryOnMount: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: !!customerId,
  });

  return {
    claims,
    ...queryResult,
  };
};
