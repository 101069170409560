export const BLUE = "#005CFF";
export const DARK_BLUE = "#002566";
export const LIGHT_BLUE = "#F7FAFF";
export const SKY_BLUE = "#EDF3FF";

export const DARK_VIOLET = "#7B84B0";
export const LIGHT_VIOLET = "#F5F6FF";

export const DARK_GREEN = "#2D7738";
export const LIGHT_GREEN = "#F2F8F2";

export const DARK_RED = "#970C0C";
export const RED = "#D21C1C";
export const LIGHT_RED = "#FCF3F3";

export const DARK_ORANGE = "#BA5D00";
export const LIGHT_ORANGE = "#FEF8F5";

export const DARK_GRAY = "#3E4E63";
export const GRAY = "#C4C4C4";
export const LIGHT_GRAY = "#F7F8F9";
