import { Anchor, Center, Group, Stack, Text, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useClaimsQuery } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import { t } from "i18next";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { RoutePath } from "types";
import { ClaimDetails, ClaimFilesList, ClaimIncidentDescription } from "./components";

export const ClaimPage: React.FC = () => {
  usePageViewTracking("claim-details");
  const { claimId } = useParams<"claimId">();
  const { claims, isLoading } = useClaimsQuery();

  const claim = useMemo(() => {
    if (!claims) return undefined;

    return claims.find(({ id }) => id === claimId);
  }, [claims, claimId]);

  if (isLoading) return null;

  if (!claim)
    return (
      <Center>
        <Text weight={600}>{t("claim.claimNotFound")}</Text>
      </Center>
    );

  const { rental_item_infos } = claim;

  return (
    <Stack spacing="lg">
      <Anchor component={Link} to={RoutePath.CLAIMS}>
        <Group spacing="xs">
          <IconArrowLeft />
          <Text weight={700}>{t("claim.backToClaims")}</Text>
        </Group>
      </Anchor>

      <Stack spacing="xs">
        <Title size="h4">{t("claim.claim")}</Title>
        <Title order={2} size="h5">
          {rental_item_infos.name}
        </Title>
      </Stack>

      <ClaimDetails claim={claim} />
      <ClaimIncidentDescription description={claim.incident_description} />
      <ClaimFilesList files={claim.claim_document ? claim.claim_document.files : []} />
    </Stack>
  );
};
