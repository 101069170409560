import { Stack, Text, Title, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { BadgeIcon, Button } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

interface Props {
  close: () => void;
}

export const HelpRequestSuccess: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={({ fn: { smallerThan } }) => ({
        [smallerThan(SMALL_SCREEN_BREAKPOINT)]: { flex: 1, justifyContent: "space-between" },
      })}
      spacing="lg"
    >
      <Stack spacing="lg">
        <BadgeIcon icon={IconCheck} state="positive" size={rem(40)} sx={{ alignSelf: "center" }} />
        <Title size="h4">{t("help.yourHelpRequestHasBeenSent")}</Title>
        <Stack spacing="xs">
          <Text>{t("help.weDoOurBestToAnswerYouAsap")}</Text>
          <Text>{t("help.youWillReceiveTheAnswerByEmail")}</Text>
        </Stack>
      </Stack>

      <Button
        sx={({ fn: { largerThan } }) => ({
          [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-start" },
        })}
        onClick={close}
      >
        {t("help.backToPreviousPage")}
      </Button>
    </Stack>
  );
};
