import { Image, Modal, SimpleGrid, Stack, Text } from "@mantine/core";
import { Button } from "components";
import { createDate, formatDate } from "helpers/date";
import { createPrice, formatPrice } from "helpers/price";
import React from "react";
import { useTranslation } from "react-i18next";
import { DARK_GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";
import { CoverageType } from "types";
import { useStyles } from "./ItemDetailsModal.styles";

export interface Item {
  id: string;
  name: string;
  image: string | null;
  reference: string | null;
  serial_number: string | null;
  rent: boolean;
  first_instalment_with_tax: number;
  other_instalment_with_tax: number;
  duration: number | null;
  rental_start_date: string | null;
  rental_end_date: string | null;
}

interface Props {
  opened: boolean;
  close: () => void;
  item: Item;
  insuredCoverageTypes: CoverageType[];
}

export const ItemDetailsModal: React.FC<Props> = ({
  opened,
  close,
  item: {
    name,
    image,
    reference,
    serial_number,
    rent,
    first_instalment_with_tax,
    other_instalment_with_tax,
    duration,
    rental_start_date,
    rental_end_date,
  },
  insuredCoverageTypes,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Modal opened={opened} onClose={close} title={name}>
      <Stack sx={{ flex: 1 }} justify="space-between">
        <Stack spacing="sm">
          {!!image && (
            <Image
              src={image}
              height={100}
              width={100}
              fit="cover"
              radius="sm"
              className={classes.itemImage}
            />
          )}

          <SimpleGrid cols={2} verticalSpacing="xs" sx={{ alignItems: "center" }}>
            {!!reference && (
              <>
                <Text color={DARK_GRAY}>{t("itemDetailsModal.reference")}</Text>
                <Text weight={700}>{reference}</Text>
              </>
            )}

            {!!serial_number && (
              <>
                <Text color={DARK_GRAY}>{t("itemDetailsModal.serialNumber")}</Text>
                <Text weight={700}>{serial_number}</Text>
              </>
            )}

            {rent ? (
              <>
                <Text color={DARK_GRAY}>{t("itemDetailsModal.firstInstalment")}</Text>
                <Text weight={700}>{formatPrice(createPrice(first_instalment_with_tax))}</Text>

                <Text color={DARK_GRAY}>{t("itemDetailsModal.otherInstalment")}</Text>
                <Text weight={700}>{formatPrice(createPrice(other_instalment_with_tax))}</Text>

                <Text color={DARK_GRAY}>{t("itemDetailsModal.subscriptionDuration")}</Text>
                <Text weight={700}>
                  {duration === -1
                    ? t("itemDetailsModal.withoutCommitment")
                    : t("itemDetailsModal.monthDuration", { duration })}
                </Text>

                {!!rental_start_date && (
                  <>
                    <Text color={DARK_GRAY}>{t("itemDetailsModal.rentalStart")}</Text>
                    <Text weight={700}>{formatDate(createDate(rental_start_date))}</Text>
                  </>
                )}

                {!!rental_end_date && (
                  <>
                    <Text color={DARK_GRAY}>{t("itemDetailsModal.rentalEnd")}</Text>
                    <Text weight={700}>{formatDate(createDate(rental_end_date))}</Text>
                  </>
                )}
              </>
            ) : (
              <>
                <Text color={DARK_GRAY}>{t("itemDetailsModal.purchasedPrice")}</Text>
                <Text weight={700}>{formatPrice(createPrice(first_instalment_with_tax))}</Text>
              </>
            )}

            <Text color={DARK_GRAY}>{t("itemDetailsModal.insurance")}</Text>
            <Text weight={700}>
              {insuredCoverageTypes.length > 0
                ? t("itemDetailsModal.insuredCoverageTypes", {
                    coverageTypes: insuredCoverageTypes.map((coverageType) =>
                      t(`itemDetailsModal.coverageTypes.${coverageType}`)
                    ),
                  })
                : t("itemDetailsModal.none")}
            </Text>
          </SimpleGrid>
        </Stack>

        <Button
          sx={({ fn: { largerThan } }) => ({
            [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
          })}
          onClick={close}
        >
          {t("itemDetailsModal.quit")}
        </Button>
      </Stack>
    </Modal>
  );
};
