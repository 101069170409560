import { Stack, Title } from "@mantine/core";
import { useClaimsQuery } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { ClaimCard, EmailNotification, NoClaims } from "./components";

export const ClaimsPage: React.FC = () => {
  usePageViewTracking("claims-list");
  const { t } = useTranslation();
  const { claims } = useClaimsQuery();

  if (!claims) return null;

  return (
    <Stack spacing="lg">
      <Title size="h3">{t("claim.myClaims")}</Title>

      {claims.length === 0 ? (
        <NoClaims />
      ) : (
        <Stack>
          <EmailNotification />

          {claims.map((claim) => (
            <ClaimCard key={claim.id} claim={claim} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
