import { Stack } from "@mantine/core";
import { AddressAutocompleteInput, Button, TextInput } from "components";
import { useForm } from "hooks";
import { useFormTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { customerAddressUpdateSchema } from "schemas";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Customer, CustomerUpdate } from "types";

interface Props {
  customer: Customer;
  updateCustomer: (customer: CustomerUpdate) => void;
  isUpdatingCustomer: boolean;
}

export const ReturnForm: React.FC<Props> = ({
  customer: { id: customerId, shipping_address },
  updateCustomer,
  isUpdatingCustomer,
}) => {
  const { t } = useTranslation();
  const { formProps } = useFormTracking("customer-information-form");

  const initialShippingAddress = shipping_address
    ? {
        streetAddress: shipping_address.street_address,
        city: shipping_address.city,
        zipCode: shipping_address.zip_code,
        country: shipping_address.country,
      }
    : undefined;

  const { getInputProps, setFieldValue, errors, onSubmit } = useForm({
    schema: customerAddressUpdateSchema,
    initialValues: {
      street_address: "",
      street_address_2: "",
      city: "",
      zip_code: "",
      country: "",
      ...shipping_address,
    },
  });

  return (
    <form
      noValidate
      {...formProps}
      onSubmit={onSubmit((shipping_address) =>
        updateCustomer({ id: customerId, shipping_address })
      )}
      style={{ flex: 1 }}
    >
      <Stack justify="space-between" h="100%">
        <Stack spacing="sm">
          <AddressAutocompleteInput
            label={t("return.address")}
            required
            initialAddress={initialShippingAddress}
            onChange={(address) => {
              setFieldValue("street_address", address?.streetAddress ?? "");
              setFieldValue("zip_code", address?.zipCode ?? "");
              setFieldValue("city", address?.city ?? "");
              setFieldValue("country", address?.country ?? "");
            }}
            error={!!errors["street_address"]}
            name="shipping-address"
          />

          <TextInput
            label={t("return.addressComplement")}
            {...getInputProps("street_address_2")}
            name="shipping-address-complement"
          />
        </Stack>

        <Button
          type="submit"
          loading={isUpdatingCustomer}
          sx={({ fn: { largerThan } }) => ({
            [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
          })}
        >
          {t("return.save")}
        </Button>
      </Stack>
    </form>
  );
};
