import { customerAddressSchema, customerSchema } from "schemas";
import { z } from "zod";

export enum CustomerCredentialType {
  EMAIL = "email",
  PHONE = "phone",
}

export type CustomerEmailCredential = {
  [CustomerCredentialType.EMAIL]: string;
};

export type CustomerPhoneCredential = {
  [CustomerCredentialType.PHONE]: string;
};

export type CustomerCredential = CustomerEmailCredential | CustomerPhoneCredential;

export type CustomerAuthCodeValidation = CustomerCredential & { auth_code: string };

export type CustomerAddress = z.infer<typeof customerAddressSchema>;

export type Customer = z.infer<typeof customerSchema>;

export type CustomerUpdate = Pick<Customer, "id"> &
  Partial<Pick<Customer, "billing_address" | "shipping_address">>;

export type CustomerPhoneUpdateRequest = { customerId: string; phone_to_validate: string };

export type CustomerPhoneUpdate = { customerId: string; phone_validation_code: string };

export type CustomerEmailUpdateRequest = { customerId: string; email_to_validate: string };

export type CustomerEmailUpdate = { customerId: string; email_validation_code: string };
