import { createStyles } from "@mantine/core";

interface Params {
  viewportHeight: number | string;
}

export const useStyles = createStyles<string, Params>((_theme, { viewportHeight }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: viewportHeight,
    justifyContent: "space-between",
  },
  body: { flex: 1 },
  main: { minHeight: "auto" },
}));
