import { Group, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Button } from "components";
import { useAppContext } from "contexts/AppContext";
import { useRetailerQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { RentalItem } from "types";
import { useStyles } from "./RentalItemClaimButton.styles";

interface Props {
  rentalItem: RentalItem;
  retailerId: string;
}

export const RentalItemClaimButton: React.FC<Props> = ({ rentalItem, retailerId }) => {
  const { t } = useTranslation();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const { trackClick } = useEventTracking();
  const { openClaimCreation } = useAppContext();
  const { retailer } = useRetailerQuery(retailerId);
  const { classes } = useStyles();

  if (!rentalItem.rental_start_date) return null;

  return (
    <>
      <Modal opened={modalOpened} onClose={closeModal} title={t("rental.help")}>
        <Stack className={classes.container} justify="space-between">
          <Text>
            <Trans i18nKey="rental.claimMessage" values={{ retailerName: retailer?.name }} />
          </Text>

          <Group spacing="xs" className={classes.buttonsWrapper}>
            <Button w="100%" onClick={closeModal} variant="ghost">
              {t("rental.quit")}
            </Button>

            <Link target="_blank" to={String(retailer?.urls_config.claim_form_url)}>
              <Button w="100%">{t("rental.reportAClaim")}</Button>
            </Link>
          </Group>
        </Stack>
      </Modal>

      <Button
        variant="secondary"
        onClick={() => {
          trackClick(TrackingEventCategory.BUTTON, "click_create_claim");

          if (!retailer?.activate_claims && !!retailer?.urls_config.claim_form_url) {
            openModal();
          } else {
            openClaimCreation(rentalItem, retailerId);
          }
        }}
      >
        {t("rental.help")}
      </Button>
    </>
  );
};
