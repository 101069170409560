import { Stack, Textarea } from "@mantine/core";
import { Button } from "components";
import { useForm, useRentalsQuery, useSessionsQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { RetailerHelpRequest } from "types";
import { z } from "zod";
import { useStyles } from "./HelpRequestForm.styles";

interface Props {
  sendHelpRequest: (helpRequest: RetailerHelpRequest) => void;
  isSendingHelpRequest: boolean;
}

export const HelpRequestForm: React.FC<Props> = ({ sendHelpRequest, isSendingHelpRequest }) => {
  const { t } = useTranslation();
  const { rentals } = useRentalsQuery();
  const { sessions } = useSessionsQuery();
  const { rentalId } = useParams<"rentalId">();
  const { sessionId } = useParams<"sessionId">();
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();

  const retailerId = useMemo(() => {
    if (rentalId) return rentals?.find((rental) => rental.id === rentalId)?.retailer_infos.id;

    if (sessionId) return sessions?.find((session) => session.id === sessionId)?.retailer_infos.id;
  }, [rentalId, rentals, sessionId, sessions]);

  const { onSubmit, getInputProps } = useForm({
    schema: z.object({
      message: z.string().nonempty(String(t("help.requiredField"))),
    }),
    initialValues: {
      message: "",
    },
  });

  if (!retailerId) return null;

  return (
    <form
      onSubmit={onSubmit(({ message }) =>
        sendHelpRequest({
          retailer_id: retailerId,
          rental_id: rentalId,
          session_id: sessionId,
          message,
        })
      )}
      noValidate
      className={classes.form}
    >
      <Stack className={classes.wrapper}>
        <Textarea
          placeholder={String(t("help.helpMessagePlaceholder"))}
          autosize
          minRows={3}
          data-testid="help-request-message-input"
          {...getInputProps("message")}
        />

        <Button
          type="submit"
          loading={isSendingHelpRequest}
          data-testid="help-request-submit-button"
          onClick={() => trackClick(TrackingEventCategory.BUTTON, "click_send_message")}
          className={classes.submitButton}
        >
          {t("help.sendMyHelpRequest")}
        </Button>
      </Stack>
    </form>
  );
};
