import { RetailerCoverageConfiguration } from "types";

export const getItemInsuredCoverageTypes = (
  coverageConfigurationCode: string | null,
  retailerCoverageConfigurations: RetailerCoverageConfiguration[]
) => {
  const coverageConfiguration = retailerCoverageConfigurations.find(
    ({ code }) => code === coverageConfigurationCode
  );

  if (!coverageConfiguration) return [];

  return coverageConfiguration.coverages
    .filter(
      ({ finance_owner, operation_owner }) =>
        finance_owner === "insurer" || operation_owner === "insurer"
    )
    .map(({ coverage_type }) => coverage_type);
};
