import { Group, Modal, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronRight, IconPencil } from "@tabler/icons-react";
import { Button } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";
import { Customer } from "types";
import { useStyles } from "./ProfileCustomerEmail.styles";
import { ProfileCustomerEmailUpdate } from "./Update";

interface Props {
  customer: Customer;
}

export const ProfileCustomerEmail: React.FC<Props> = ({ customer }) => {
  const [updateModalOpened, { open: openUpdateModal, close: closeUpdateModal }] = useDisclosure();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT - 1}px)`);

  return (
    <>
      <Paper
        radius="md"
        withBorder
        p="sm"
        className={classes.container}
        onClick={() => isSmallScreen && openUpdateModal()}
      >
        <Group position="apart">
          <Stack spacing="xs">
            <Text fw={700}>{t("profile.emailAddress")}</Text>
            <Text>{customer.email}</Text>
          </Stack>

          <Button
            variant="ghost"
            leftIcon={<IconPencil />}
            className={classes.editButton}
            onClick={openUpdateModal}
          >
            {t("profile.edit")}
          </Button>

          <IconChevronRight color={GRAY} className={classes.chevronIcon} />
        </Group>
      </Paper>

      <Modal
        opened={updateModalOpened}
        onClose={closeUpdateModal}
        title={t("profile.emailAddress")}
      >
        <ProfileCustomerEmailUpdate customer={customer} closeModal={closeUpdateModal} />
      </Modal>
    </>
  );
};
