import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "contexts/AuthContext";
import { useCallback } from "react";
import { getCustomerRentals } from "services/api/customer";

export const useRentalsQuery = () => {
  const { customerId } = useAuthContext();

  const queryFn = useCallback(() => getCustomerRentals(String(customerId)), [customerId]);

  const { data: rentals, ...queryResult } = useQuery({
    queryKey: ["rentals", customerId],
    queryFn,
    retry: false,
    retryOnMount: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: !!customerId,
  });

  return {
    rentals,
    ...queryResult,
  };
};
