import { useQueries } from "@tanstack/react-query";
import { getRetailer } from "services/api/retailer";

export const useRetailerQueries = (retailerIds: string[]) => {
  const queries = useQueries({
    queries: retailerIds.map((retailerId) => ({
      queryKey: ["retailer", retailerId],
      queryFn: () => getRetailer(retailerId),
      retry: false,
      retryOnMount: false,
      staleTime: Infinity,
    })),
  });

  return queries.map(({ data: retailer, ...queryResult }) => ({ retailer, ...queryResult }));
};
