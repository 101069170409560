import { Anchor, Grid, Paper, Text } from "@mantine/core";
import { createDate, formatDate } from "helpers/date";
import { createPrice, formatPrice, sumPrices } from "helpers/price";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DeliveryMethod, Session } from "types";
import { OrderItem } from "../helpers";
import { useStyles } from "./OrderDetailsCard.styles";

interface Props {
  session: Session;
  orderItems: OrderItem[];
}

export const OrderDetailsCard: React.FC<Props> = ({ session, orderItems }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const firstInstalmentWithTaxTotalPrice = sumPrices(
    orderItems.map((orderItem) => createPrice(orderItem.first_instalment_with_tax))
  );

  const otherInstalmentWithTaxTotalPrice = sumPrices(
    orderItems.map((orderItem) => createPrice(orderItem.other_instalment_with_tax))
  );

  return (
    <Paper shadow="xl" radius="md" p="sm">
      <Grid gutter="sm">
        <Grid.Col className={classes.col}>
          <Text size="sm">{t("order.firstInstalment")}</Text>
          <Text size="sm" weight={700}>
            {formatPrice(firstInstalmentWithTaxTotalPrice)}
          </Text>
        </Grid.Col>

        <Grid.Col className={classes.col}>
          <Text size="sm">{t("order.otherInstalment")}</Text>
          <Text size="sm" weight={700}>
            {formatPrice(otherInstalmentWithTaxTotalPrice)}
          </Text>
        </Grid.Col>

        <Grid.Col className={classes.col}>
          <Text size="sm">{t("order.subscription")}</Text>
          <Text size="sm" weight={700}>
            {formatDate(createDate(session.created_at))}
          </Text>
        </Grid.Col>

        {session.rental && (
          <Grid.Col className={classes.col}>
            <Text size="sm">{t("order.rental")}</Text>
            <Anchor
              size="sm"
              td="underline"
              weight={700}
              component={Link}
              to={`/rentals/${session.rental.id}`}
            >
              {session.rental.reference}
            </Anchor>
          </Grid.Col>
        )}

        {session.delivery_method === DeliveryMethod.STORE_PICKUP ? (
          <Grid.Col className={classes.col}>
            <Text size="sm">{t("order.store")}</Text>
            <Text size="sm" weight={700}>
              {session.store_name}
            </Text>
          </Grid.Col>
        ) : (
          !!session.shipping_address && (
            <Grid.Col className={classes.col} sx={{ flexBasis: "100%" }}>
              <Text size="sm">{t("order.shipping")}</Text>
              <Text size="sm" weight={700}>
                {`${session.shipping_address?.street_address}, ${session.shipping_address?.zip_code} ${session.shipping_address?.city}`}
              </Text>
            </Grid.Col>
          )
        )}
      </Grid>
    </Paper>
  );
};
