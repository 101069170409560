const buildFormDataFields = (formData: FormData, data: unknown, name = ""): void => {
  if (data instanceof Array) {
    data.forEach((value) => {
      buildFormDataFields(formData, value, `${name}[]`);
    });
  } else if (data instanceof File) {
    formData.append(name, data);
  } else if (data instanceof Object) {
    Object.entries(data).forEach(([key, value]) => formData.append(`${name}[${key}]`, String(value)))
  } else {
    formData.append(name, String(data));
  }
};

export const buildFormData = (data: Record<string, unknown>) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => buildFormDataFields(formData, value, key));
  return formData;
};
