import {
  ContextStylesParams,
  MantineThemeOverride,
  NotificationStylesParams,
  getStylesRef,
  rem,
} from "@mantine/core";
import { HEADER_HEIGHT } from "components";
import { MEDIUM_SCREEN_BREAKPOINT, SMALL_SCREEN_BREAKPOINT } from "./breakpoints";
import { BLUE, DARK_GRAY, LIGHT_BLUE, SKY_BLUE } from "./colors";

export const mantineTheme: MantineThemeOverride = {
  primaryColor: "dark",
  fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial",sans-serif',
  lineHeight: 1.5,
  spacing: {
    xs: "0.5rem",
    sm: "1rem",
    md: "1.5rem",
    lg: "2rem",
    xl: "2.5rem",
  },
  shadows: {
    xs: "0px 1px 1px rgba(0, 0, 0, 0.15)",
    sm: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
    md: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    lg: "0px 1px 20px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    xl: "0px 4px 20px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
  },
  headings: {
    sizes: {
      h1: { fontSize: "3.812rem", lineHeight: "62px", fontWeight: 700 },
      h2: { fontSize: "3.062rem", lineHeight: "54px", fontWeight: 700 },
      h3: { fontSize: "2.438rem", lineHeight: "42px", fontWeight: 700 },
      h4: { fontSize: "1.938rem", lineHeight: "40px", fontWeight: 700 },
      h5: { fontSize: "1.562rem", lineHeight: "30px", fontWeight: 700 },
      h6: { fontSize: "1.250rem", lineHeight: "24px", fontWeight: 700 },
    },
  },
  globalStyles: () => ({
    a: {
      all: "unset",
    },
    input: {
      "&:disabled": {
        opacity: "1 !important",
      },
    },
    textarea: {
      "&:disabled": {
        opacity: "1 !important",
      },
    },
  }),
  components: {
    Loader: {
      defaultProps: {
        color: BLUE,
        sx: { "g > g": { strokeWidth: 3 } },
      },
    },
    Group: {
      defaultProps: {
        noWrap: true,
      },
    },
    TextInput: {
      defaultProps: {
        size: "md",
      },
    },
    PinInput: {
      defaultProps: {
        placeholder: "",
      },
      styles: ({ radius }) => ({
        input: {
          borderWidth: 2,
          borderRadius: radius.md,
          ":focus": { borderColor: BLUE },
        },
      }),
    },
    Textarea: {
      defaultProps: {
        size: "md",
      },
      styles: ({ colors }) => ({
        input: {
          ":focus": {
            borderColor: BLUE,
          },
          "&[data-invalid]::placeholder": {
            color: colors.gray[5],
          },
        },
      }),
    },
    Popover: {
      defaultProps: {
        withinPortal: true,
      },
    },
    Autocomplete: {
      defaultProps: {
        size: "md",
      },
    },
    DatePickerInput: {
      defaultProps: {
        size: "md",
      },
    },
    Badge: {
      defaultProps: {
        size: "lg",
      },
    },
    Select: {
      styles: ({ colors }) => ({
        input: {
          height: "2rem",
          paddingLeft: "0.5rem",
          fontWeight: 700,
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
          ":focus": { borderColor: colors.gray[4] },
        },
        dropdown: {
          marginTop: -4,
        },
        itemsWrapper: {
          padding: 0,
        },
        item: {
          padding: `0.25rem 0.5rem`,
          borderRadius: 0,
          fontWeight: 700,
        },
      }),
    },
    Modal: {
      defaultProps: {
        centered: true,
        radius: "lg",
        transitionProps: { transition: "slide-up" },
        overlayProps: { opacity: 0.4 },
      },
      styles: ({ fontSizes, spacing, colors, fn: { smallerThan, largerThan } }) => ({
        inner: {
          width: "auto",
          left: 0,
          right: 0,
          [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
            padding: spacing.md,
          },
          [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
            padding: spacing.xl,
          },
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
            padding: 0,
            top: "10vh",
            alignItems: "flex-end",
          },
          [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
            right: "var(--removed-body-scroll-bar-size)",
          },
        },
        content: {
          flex: 1,
          [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
            maxWidth: rem(600),
          },
          [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
            maxWidth: "50%",
          },
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
            height: `calc(100dvh - ${HEADER_HEIGHT}px)`,
            maxHeight: "unset",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            display: "flex",
            flexDirection: "column",
          },
        },
        header: {
          padding: `${spacing.sm} 0`,
          margin: `0 ${spacing.sm}`,
          borderBottomStyle: "solid",
          borderBottomColor: colors.gray[3],
          borderWidth: 1,
        },
        title: {
          fontSize: fontSizes.md,
          fontWeight: 700,
        },
        body: {
          padding: `${spacing.sm} !important`,

          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
          },
        },
        close: {
          color: DARK_GRAY,
        },
      }),
    },
    Tabs: {
      defaultProps: {
        variant: "pills",
      },
      styles: ({ radius, fontSizes, spacing }, _params, { variant }: ContextStylesParams) => {
        switch (variant) {
          case "pills":
            return {
              tabsList: {
                columnGap: spacing.sm,
              },
              tab: {
                borderRadius: radius.xl,
                fontSize: fontSizes.md,
                fontWeight: 700,
                padding: `${spacing.sm} ${spacing.md}`,
                backgroundColor: SKY_BLUE,
                ":hover": {
                  backgroundColor: SKY_BLUE,
                },
                "&[data-active]": {
                  backgroundColor: BLUE,
                  ":hover": {
                    backgroundColor: BLUE,
                  },
                },
              },
            };
          default:
            return { tabsList: {}, tab: {} };
        }
      },
    },
    Chip: {
      styles: ({ radius, spacing, fontSizes }) => ({
        root: {},
        label: {
          width: "100%",
          height: "auto",
          borderRadius: radius.md,
          padding: spacing.sm,
          fontSize: fontSizes.md,
          "&[data-checked]": {
            padding: spacing.sm,
            ":not([data-disabled])": {
              borderColor: BLUE,
              color: BLUE,
              backgroundColor: LIGHT_BLUE,
            },
          },
        },
        iconWrapper: { display: "none" },
      }),
    },
    LoadingOverlay: {
      styles: {
        root: {
          ref: getStylesRef("loading-overlay-root"),
        },
      },
    },
    Dropzone: {
      defaultProps: {
        radius: "md",
      },
      styles: ({ spacing }) => ({
        root: {
          borderWidth: 1,
          "&[data-accept]": {
            backgroundColor: LIGHT_BLUE,
            borderColor: BLUE,
          },
          "&[tabindex]:hover": { backgroundColor: LIGHT_BLUE },
          ":not([tabindex])": {
            ":hover": { backgroundColor: "transparent" },
            cursor: "not-allowed",
          },
          [`.${getStylesRef("loading-overlay-root")}`]: {
            justifyContent: "flex-end",
            paddingRight: spacing.sm,
          },
        },
      }),
    },
    Image: {
      defaultProps: {
        withPlaceholder: true,
      },
    },
    Notification: {
      styles: (
        { shadows, colors, primaryColor },
        { color = primaryColor }: NotificationStylesParams
      ) => ({
        root: {
          boxShadow: shadows.xl,
        },
        title: {
          fontWeight: 700,
          color: colors[color][7],
        },
        description: {
          color: colors.dark[7],
        },
      }),
    },
    Pagination: {
      styles: ({ fn: { darken } }) => ({
        control: {
          "&[data-active]": {
            backgroundColor: BLUE,

            ":not([data-disabled]):hover": {
              backgroundColor: darken(BLUE, 0.1),
            },
          },
        },
      }),
    },
    Alert: {
      defaultProps: {
        radius: "md",
      },
    },
    Avatar: {
      styles: ({ fontSizes, black }) => ({
        placeholder: {
          backgroundColor: SKY_BLUE,
          fontSize: fontSizes.xl,
          color: black,
        },
      }),
    },
    Tooltip: {
      defaultProps: {
        position: "top",
        withArrow: true,
        multiline: true,
        width: 200,
        events: { hover: true, focus: false, touch: true },
      },
      styles: {
        tooltip: {
          fontWeight: 600,
          whiteSpace: "pre-wrap",
        },
      },
    },
  },
};
