import { Group, Paper, Skeleton, Stack } from "@mantine/core";
import React from "react";
import { useStyles } from "./RentalCard.styles";

export const RentalSkeletonCard: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Paper shadow="md" p="xs" radius="md" className={classes.card}>
      <Paper radius="md">
        <Group position="apart" align="stretch" className={classes.container}>
          <Stack spacing="sm" align="start" className={classes.leftSection}>
            <Stack spacing="sm">
              <Skeleton w={160} height={20} />
              <Skeleton w={260} height={30} />
            </Stack>

            <div className={classes.rentalStatusBadgeWrapper}>
              <Skeleton w={140} height={25} />
            </div>
          </Stack>

          <Stack spacing="xs" align="end" justify="space-between" className={classes.rightSection}>
            <Skeleton w={120} height={50} />

            <Skeleton w={185} height={35} radius="xl" />
          </Stack>
        </Group>
      </Paper>
    </Paper>
  );
};
