import { createStyles } from "@mantine/core";
import { BLUE } from "theme";

interface Params {
  color?: string;
  variant?: "primary" | "secondary" | "ghost";
}

export const useStyles = createStyles<string, Params>(
  ({ radius, fn: { darken, rgba } }, { color = BLUE, variant = "primary" }) => ({
    root: {
      borderRadius: radius.xl,
      borderWidth: variant === "secondary" ? 1 : 0,
      borderColor: color,
      backgroundColor: variant === "primary" ? color : "transparent",

      ":hover": {
        backgroundColor: variant === "primary" ? darken(color, 0.1) : rgba(color, 0.04),
      },
    },

    label: {
      fontWeight: 700,
      color: variant === "primary" ? undefined : color,
    },
    icon: { color: variant === "primary" ? undefined : color },
  })
);
