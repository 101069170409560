import { Paper, Stack, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Customer } from "types";

interface Props {
  customer: Customer;
}

export const ProfileCustomerCompanyDetails: React.FC<Props> = ({
  customer: { is_company, company_registration_id, company_vat_id },
}) => {
  const { t } = useTranslation();

  if (!is_company) return null;

  return (
    <Paper radius="md" withBorder p="sm">
      <Stack spacing="xs">
        <Text fw={700}>{t("profile.company")}</Text>
        <Text>
          {t("profile.companyRegistrationId", { registrationId: company_registration_id })}
        </Text>
        <Text>{t("profile.companyVatId", { vatId: company_vat_id })}</Text>
      </Stack>
    </Paper>
  );
};
