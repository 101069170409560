import { Group, Image, Paper, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Button, SessionStatusBadge } from "components";
import { createDate, formatDate } from "helpers/date";
import { useRetailerQuery } from "hooks";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { TrackingEventCategory } from "services/analytics";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Session } from "types";
import { useStyles } from "./OrderCard.styles";

interface Props {
  session: Session;
}

export const OrderCard: React.FC<Props> = ({ session }) => {
  const { t } = useTranslation();
  const { id: sessionId, status } = session;
  const { retailer } = useRetailerQuery(session.retailer_infos.id);
  const { trackClick } = useEventTracking();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT - 1}px)`);

  return (
    <Paper
      shadow="md"
      p="xs"
      radius="md"
      className={classes.card}
      onClick={() => {
        if (isSmallScreen) {
          trackClick(TrackingEventCategory.LINK, "click_see_session");
          navigate(sessionId);
        }
      }}
    >
      <Paper radius="md">
        <Group position="apart" align="stretch" className={classes.container}>
          <Stack spacing="xs" align="start" className={classes.leftSection}>
            <Stack spacing="xs">
              <Text tt="uppercase" weight={700}>
                {session.retailer_infos.name}
              </Text>

              <Text size="xl" weight={700}>
                {t("order.orderLabel", { reference: session.reference })}
              </Text>

              <Text>{formatDate(createDate(session.created_at))}</Text>
            </Stack>

            <div className={classes.sessionStatusBadgeWrapper}>
              <SessionStatusBadge status={status} />
            </div>
          </Stack>

          <Stack spacing="xs" align="end" justify="space-between" className={classes.rightSection}>
            {retailer ? (
              <Image src={retailer.style.logo_url} fit="contain" height={50} width={120} />
            ) : (
              <div style={{ height: 50 }} />
            )}

            <Link
              to={sessionId}
              onClick={() => trackClick(TrackingEventCategory.LINK, "click_see_session")}
            >
              <Button w="100%">{t("order.consult")}</Button>
            </Link>
          </Stack>
        </Group>
      </Paper>
    </Paper>
  );
};
