import { CustomerCredential } from "types";
import { z } from "zod";
import { apiClient } from "../client";

export const requestAuthCode = async (customerCredential: CustomerCredential) =>
  z
    .object({
      attempts_left: z.number(),
    })
    .parse((await apiClient.get("v1/request_auth_code", { params: customerCredential })).data);
