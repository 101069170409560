import { Group, Stack, Text } from "@mantine/core";
import { useDidUpdate } from "@mantine/hooks";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "components";
import { formatPrice } from "helpers/price";
import { getRentalPaymentInfo, invalidateRentalsQuery } from "helpers/rental";
import { useCustomerQuery, useRetailerQuery } from "hooks";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PaymentSketch } from "resources/sketches/payment.svg";
import { getCustomerRentals } from "services/api/customer";
import { retryPayment } from "services/api/invoice";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { FinancingMode, Invoice, InvoicePaymentMethod, InvoicePaymentStatus, Rental } from "types";
import { RentalPaymentMethodUpdate } from "../../../PaymentMethodUpdate";
import { useStyles } from "./RentalUnpaidInvoicesRegularization.styles";
import {
  RegularizationStatus,
  RentalUnpaidInvoicesRegularizationStatus,
} from "./RentalUnpaidInvoicesRegularizationStatus";

interface Props {
  rental: Rental;
  unpaidInvoices: Invoice[];
  totalUnpaidPrice: Dinero.Dinero;
  closeModal: () => void;
}

export const RentalUnpaidInvoicesRegularization: React.FC<Props> = ({
  rental,
  unpaidInvoices,
  totalUnpaidPrice,
  closeModal,
}) => {
  const [isRefetchingInvoices, setIsRefetchingInvoices] = useState(false);
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);
  const [regularizationStatus, setRegularizationStatus] = useState<RegularizationStatus>();
  const { customer } = useCustomerQuery();
  const { retailer } = useRetailerQuery(rental.retailer_infos.id);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const paymentInfo = useMemo(() => getRentalPaymentInfo(rental), [rental]);

  const canUpdatePaymentMethod = useMemo(() => {
    if (!retailer) return undefined;

    return (
      paymentInfo?.payment_method !== InvoicePaymentMethod.SEPA_DEBIT ||
      retailer.scoring_configuration.financing_mode !== FinancingMode.CACF
    );
  }, [paymentInfo?.payment_method, retailer]);

  const { mutate: retryPayments, isLoading: isRetryingPayments } = useMutation({
    mutationFn: () => Promise.all(unpaidInvoices.map((invoice) => retryPayment(invoice.id))),
    onSuccess: () => setIsRefetchingInvoices(true),
    onError: () => setRegularizationStatus(RegularizationStatus.FAILED),
  });

  const { data: updatedInvoices } = useQuery({
    queryFn: async () => {
      if (!customer) return;

      const updatedRental = (await getCustomerRentals(customer.id)).find((r) => r.id === rental.id);

      if (!updatedRental || !updatedRental.invoices) return null;

      const updatedInvoices = updatedRental.invoices;
      const unpaidInvoiceById = keyBy(unpaidInvoices, "id");

      if (
        !updatedInvoices
          .filter((invoice) => Object.keys(unpaidInvoiceById).includes(invoice.id))
          .every((invoice) => invoice.updated_at !== unpaidInvoiceById[invoice.id].updated_at)
      )
        return null;

      setIsRefetchingInvoices(false);

      return updatedInvoices;
    },
    queryKey: ["updated-rental-invoices", String(rental.id)],
    refetchInterval: 1000,
    enabled: isRefetchingInvoices,
  });

  useDidUpdate(() => {
    if (updatedInvoices) {
      const updatedInvoicesPaymentStatuses = uniq(
        updatedInvoices.map((invoice) => invoice.payment_status)
      );

      if (updatedInvoicesPaymentStatuses.length > 1) {
        setRegularizationStatus(RegularizationStatus.INCOMPLETE);
      } else {
        const paymentStatus = updatedInvoicesPaymentStatuses[0];
        switch (paymentStatus) {
          case InvoicePaymentStatus.SUCCEEDED:
            setRegularizationStatus(RegularizationStatus.SUCCESS);
            break;
          case InvoicePaymentStatus.PENDING:
            setRegularizationStatus(RegularizationStatus.PENDING);
            break;
          case InvoicePaymentStatus.FAILED:
            setRegularizationStatus(RegularizationStatus.FAILED);
            break;
        }
      }
    }
  }, [updatedInvoices]);

  if (!paymentInfo) return null;

  if (isUpdatingPaymentMethod && !!customer)
    return (
      <Stack className={classes.container}>
        <Group>
          <PaymentSketch className={classes.paymentSketch} />
          <Text>
            {t("rental.regularizationEditPaymentMethodDescription", {
              amount: formatPrice(totalUnpaidPrice),
            })}
          </Text>
        </Group>

        <RentalPaymentMethodUpdate
          rental={rental}
          customer={customer}
          onSuccess={() => setIsUpdatingPaymentMethod(false)}
          onCancel={() => closeModal()}
        />
      </Stack>
    );

  if (regularizationStatus)
    return (
      <RentalUnpaidInvoicesRegularizationStatus
        regularizationStatus={regularizationStatus}
        totalUnpaidPrice={totalUnpaidPrice}
        closeModal={() => {
          invalidateRentalsQuery();
          closeModal();
        }}
      />
    );

  return (
    <Stack justify="space-between" className={classes.container}>
      <Group
        align="center"
        sx={({ fn: { smallerThan } }) => ({
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: { flexDirection: "column" },
        })}
      >
        <PaymentSketch />

        <Text>
          {t(`rental.regularizationPaymentMethods.${paymentInfo.payment_method}`, {
            last4: paymentInfo.payment_method_last4,
            last3: paymentInfo.payment_method_last4.slice(1),
          })}
        </Text>
      </Group>

      <Group spacing="xs" className={classes.buttonsWrapper}>
        {!!canUpdatePaymentMethod && (
          <Button variant="ghost" onClick={() => setIsUpdatingPaymentMethod(true)}>
            {t("rental.editPaymentMethod")}
          </Button>
        )}

        <Button
          onClick={() => retryPayments()}
          loading={isRetryingPayments || isRefetchingInvoices}
        >
          {t("rental.regularizeSituation")}
        </Button>
      </Group>
    </Stack>
  );
};
