import { TextInput as MantineTextInput, Text, TextInputProps } from "@mantine/core";
import React, { useState } from "react";
import { useStyles } from "./TextInput.styles";

type Props = Omit<TextInputProps, "classNames"> & {
  empty?: boolean;
  hideError?: boolean;
};

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ empty, hideError = false, onFocus, onBlur, error, value, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const { classes } = useStyles({
      focused,
      empty: empty === undefined ? !value : empty,
      withLabel: !!props.label,
      error,
      hideError,
    });

    return (
      <div>
        <MantineTextInput
          ref={ref}
          classNames={classes}
          onFocus={(event) => {
            setFocused(true);
            onFocus?.(event);
          }}
          onBlur={(event) => {
            setFocused(false);
            onBlur?.(event);
          }}
          error={!!error}
          value={value}
          {...props}
        />
        {error && !hideError && (
          <Text size="xs" color="red">
            {error}
          </Text>
        )}
      </div>
    );
  }
);
TextInput.displayName = "TextInput";
