import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  leftSection: {
    flex: 1,
  },

  invoiceFileLink: {
    display: "flex",
    cursor: "pointer",
  },

  rightSection: {
    columnGap: spacing.xl,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flexDirection: "column",
      alignSelf: "stretch",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },

  price: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      fontWeight: 700,
    },
  },

  paymentStatusBadge: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  paymentStatusBadgeIcon: {
    display: "none",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "flex",
    },
  },
}));
