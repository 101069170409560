import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  form: {
    flex: 1,
  },

  textInputWrapper: {
    "> div": {
      flex: 1,
      maxWidth: 300,
    },
  },

  buttonsWrapper: {
    alignSelf: "flex-end",
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignSelf: "stretch",
      flexDirection: "column-reverse",
      alignItems: "stretch",
    },
  },
}));
