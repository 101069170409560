import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(
  ({ spacing, fn: { smallerThan, largerThan }, fontSizes }) => ({
    root: {
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        gap: spacing.md,
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        gap: spacing.sm,
      },
    },
    input: {
      fontSize: fontSizes.xl,
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        width: "4rem",
        height: "6rem",
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        width: "3rem",
        height: "4.5rem",
      },
    },
  })
);
