import { buildFormData } from "helpers/form";
import { ClaimCreation } from "types";
import { apiClient } from "../client";

export const createClaim = async ({ rental_item_id, files, ...claim }: ClaimCreation) => {
  const formData = buildFormData({ claim, files });

  await apiClient.post(`v1/rental_items/${rental_item_id}/claims`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
