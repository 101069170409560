import { createStyles, rem } from "@mantine/core";
import { HEADER_HEIGHT } from "components";
import { BLUE, LIGHT_BLUE, MEDIUM_SCREEN_BREAKPOINT, SMALL_SCREEN_BREAKPOINT } from "theme";

interface Params {
  helpRequestOpened: boolean;
  claimCreationOpened: boolean;
}

export const SMALL_SCREEN_NAVBAR_HEIGHT = 70;

export const useStyles = createStyles<string, Params>(
  (
    { white, spacing, fontSizes, colors, shadows, fn: { smallerThan, largerThan }, radius },
    { helpRequestOpened, claimCreationOpened }
  ) => ({
    root: {
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "grid",
        columnGap: 0,
        gridTemplateColumns: "2fr 1fr 6fr 1fr 2fr",
        padding: spacing.xl,
      },

      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        paddingBottom: helpRequestOpened || claimCreationOpened ? 0 : SMALL_SCREEN_NAVBAR_HEIGHT,
      },
    },

    navbar: {
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        alignSelf: "start",
        position: "sticky",
        top: `calc(${HEADER_HEIGHT}px + ${spacing.xl})`,
      },

      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        position: "fixed",
        zIndex: 100,
        bottom: 0,
        left: 0,
        right: 0,
        height: rem(SMALL_SCREEN_NAVBAR_HEIGHT),
        backgroundColor: white,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderTopColor: colors.gray[3],
        boxShadow: shadows.lg,
        display: helpRequestOpened || claimCreationOpened ? "none" : "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
      },
    },

    space: {
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },

    link: {
      cursor: "pointer",

      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "block",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "1.5rem",
        paddingRight: "1rem",
        borderLeftStyle: "solid",
        borderLeftWidth: 3,
        borderLeftColor: "transparent",
        fontSize: fontSizes.lg,
        fontWeight: 700,
        whiteSpace: "nowrap",
      },

      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: "0.25rem",
        fontSize: fontSizes.sm,
        fontWeight: 600,
      },
    },

    activeLink: {
      color: BLUE,

      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        borderLeftColor: BLUE,
        backgroundColor: LIGHT_BLUE,
      },
    },

    linkIcon: {
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },

    linkCount: {
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },

    main: {
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: spacing.md,
      },

      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        paddingLeft: spacing.sm,
        paddingRight: spacing.sm,
      },
    },

    aside: {
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        alignSelf: "flex-start",
        position: "sticky",
        top: `calc(${HEADER_HEIGHT}px + ${spacing.xl})`,
      },

      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },

    helpBox: {
      borderRadius: radius.md,
      backgroundColor: LIGHT_BLUE,
    },
  })
);
