import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "contexts/AuthContext";
import { PropsWithChildren } from "react";

/**
 * Redirect user to the /login page if he is not logged,
 * save the current location he was trying to go to when they were redirected.
 * After being logged, redirect him to location of the page wanted
 */

export const RequireAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { customerId } = useAuthContext();
  const location = useLocation();

  if (!customerId) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!customerId) return null;

  return <>{children}</>;
};
