import * as Sentry from "@sentry/react";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse, HttpStatusCode, isAxiosError } from "axios";
import { matchPath } from "react-router-dom";
import { RoutePath } from "types";

const REQUEST_TIMEOUT = 30000; // 30 seconds

const redirectToErrorPage = () => {
  if (matchPath(RoutePath.ERROR, window.location.pathname) !== null) return;

  window.location.replace(RoutePath.ERROR);
};

const queryOnError = async (error: unknown) => {
  if (!isAxiosError(error)) {
    Sentry.captureException(error);

    // wait for sentry to send error
    await Sentry.flush(1000);

    redirectToErrorPage();
  }
};

const queryCache = new QueryCache({
  onError: queryOnError,
});

const mutationCache = new MutationCache({
  onError: queryOnError,
});

export const queryClient = new QueryClient({ queryCache, mutationCache });

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    common: {
      Authorization: (() => {
        const token = sessionStorage.getItem("customer-token");
        if (token) return `Bearer ${token}`;
        return false;
      })(),
    },
  },
  timeout: REQUEST_TIMEOUT,
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === HttpStatusCode.InternalServerError) {
      redirectToErrorPage();
    } else if (
      error.response?.status === HttpStatusCode.Unauthorized &&
      // on the following api routes, an authorized response actually means that the code is wrong => ignore error
      !error.config?.url?.endsWith("/validate_auth_code") &&
      !error.config?.url?.endsWith("/update_phone") &&
      !error.config?.url?.endsWith("/update_email")
    ) {
      sessionStorage.removeItem("customer-id");
      sessionStorage.removeItem("customer-token");

      removeApiClientToken();

      window.location.replace(RoutePath.LOGIN);
    }
    throw error;
  }
);

export const setApiClientToken = (token: string) =>
  (apiClient.defaults.headers.common.Authorization = `Bearer ${token}`);

export const removeApiClientToken = () => (apiClient.defaults.headers.common.Authorization = false);
