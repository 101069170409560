import { Box, BoxProps } from "@mantine/core";
import { Icon } from "@tabler/icons-react";
import React from "react";
import { BadgeState } from "../Badge.styles";
import { useStyles } from "./BadgeIcon.styles";

type Props = import("@mantine/utils").PolymorphicComponentProps<"div", BoxProps> & {
  icon: Icon;
  state: BadgeState;
  withBorder?: boolean;
  size?: string | number;
};

export const BadgeIcon: React.FC<Props> = ({
  icon: Icon,
  state,
  withBorder,
  size,
  className,
  ...props
}) => {
  const { classes } = useStyles({ state, withBorder, size });

  return (
    <Box className={classes.container + (className ? " " + className : "")} {...props}>
      <Icon className={classes.icon} stroke={3} />
    </Box>
  );
};
