import { Stack, Text, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { BadgeIcon, Button } from "components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Rental } from "types";

interface Props {
  returnRentals: Rental[];
  close: () => void;
}

export const ReturnSuccess: React.FC<Props> = ({ returnRentals, close }) => {
  const { t } = useTranslation();

  const retailersNames = useMemo(
    () => returnRentals.map((rental) => rental.retailer_infos.name),
    [returnRentals]
  );

  return (
    <Stack justify="space-between" spacing="lg" sx={{ flex: 1 }}>
      <Stack spacing="lg">
        <BadgeIcon icon={IconCheck} state="positive" size={rem(60)} sx={{ alignSelf: "center" }} />

        <Text>{t("return.addressSuccessfullySaved")}</Text>

        <Text weight={700}>{t("return.nextSteps", { retailersNames })}</Text>
      </Stack>

      <Button
        sx={({ fn: { largerThan } }) => ({
          [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
        })}
        onClick={close}
      >
        {t("return.close")}
      </Button>
    </Stack>
  );
};
