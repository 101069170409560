import { Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import { BadgeIcon } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ClaimFile } from "types";

interface Props {
  files: ClaimFile[] | null;
}

export const ClaimFilesList: React.FC<Props> = ({ files }) => {
  const { t } = useTranslation();

  if (!files || files.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Title order={3} size="h6">
        {t("claim.uploadedFiles")}
      </Title>
      <Paper withBorder p="sm" radius="md">
        <Stack spacing="sm">
          {files.map((file) => (
            <Group spacing="xs" key={file.id}>
              <a
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "flex", cursor: "pointer" }}
              >
                <BadgeIcon icon={IconArrowDown} state="message" bg="transparent" withBorder />
              </a>
              <Text>{file.filename}</Text>
            </Group>
          ))}
        </Stack>
      </Paper>
    </Stack>
  );
};
