import { createStyles, rem } from "@mantine/core";
import { BADGE_COLORS_BY_STATE, BadgeState } from "../Badge.styles";

interface Params {
  state: BadgeState;
  withBorder?: boolean;
  size?: string | number;
}

export const useStyles = createStyles<string, Params>(
  ({ radius }, { state, withBorder = false, size = rem(20) }) => {
    const { color, backgroundColor } = BADGE_COLORS_BY_STATE[state];

    return {
      container: {
        borderRadius: radius.xl,
        backgroundColor,
        width: size,
        height: size,
        ...(withBorder
          ? { borderStyle: "solid", borderWidth: rem(2), borderColor: color }
          : undefined),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
      },
      icon: {
        height: "70%",
        width: "70%",
        color,
      },
    };
  }
);
