import { Stack, Text, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { BadgeIcon, Button } from "components";
import { setCustomerQueryData } from "helpers/customer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { Customer } from "types";
import { ProfileCustomerPhoneUpdateForm } from "./Form";
import { ProfileCustomerPhoneValidationCodeInput } from "./ValidationCodeInput";

interface Props {
  customer: Customer;
  closeModal: () => void;
}

export const ProfileCustomerPhoneUpdate: React.FC<Props> = ({ customer, closeModal }) => {
  const [initialPhone, setInitialPhone] = useState(customer.phone);
  const [newCustomerPhone, setNewCustomerPhone] = useState<string>();
  const [attemptsLeft, setAttemptsLeft] = useState<number>();
  const [isCustomerPhoneUpdated, setIsCustomerPhoneUpdated] = useState(false);
  const { t } = useTranslation();

  if (isCustomerPhoneUpdated)
    return (
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ flex: 1 }} justify="center" align="center" spacing="sm">
          <BadgeIcon icon={IconCheck} state="positive" size={rem(40)} />
          <Text>{t("profile.updatedSuccessfully")}</Text>
        </Stack>

        <Button
          sx={({ fn: { largerThan } }) => ({
            [largerThan(SMALL_SCREEN_BREAKPOINT)]: { alignSelf: "flex-end" },
          })}
          onClick={closeModal}
        >
          {t("profile.quit")}
        </Button>
      </Stack>
    );

  if (newCustomerPhone && attemptsLeft !== undefined)
    return (
      <ProfileCustomerPhoneValidationCodeInput
        customerId={customer.id}
        onCustomerPhoneUpdated={() => {
          setCustomerQueryData({ ...customer, phone: String(newCustomerPhone) });
          setIsCustomerPhoneUpdated(true);
        }}
        onCancel={() => {
          setInitialPhone(newCustomerPhone);
          setNewCustomerPhone(undefined);
        }}
        attemptsLeft={attemptsLeft}
        setAttemptsLeft={setAttemptsLeft}
        closeModal={closeModal}
      />
    );

  return (
    <ProfileCustomerPhoneUpdateForm
      customerId={customer.id}
      initialPhone={initialPhone}
      setNewCustomerPhone={setNewCustomerPhone}
      setAttemptsLeft={setAttemptsLeft}
      closeModal={closeModal}
    />
  );
};
