import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  root: {
    padding: `${spacing.xs} ${spacing.sm}`,

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      padding: spacing.sm,
    },
  },

  message: {
    display: "flex",
    justifyContent: "space-between",
    rowGap: spacing.sm,
    alignItems: "center",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      flexDirection: "column",
    },
  },

  animationWrapper: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },
}));
