import { useMatomoContext } from "contexts/MatomoContext";
import { useEffect } from "react";

export const useFormTracking = (name: string) => {
  const { scanForForms } = useMatomoContext();

  useEffect(() => {
    scanForForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { formProps: { "data-matomo-name": name } };
};
