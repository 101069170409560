import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  image: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  rightSection: {
    flex: 1,
  },

  smallScreenPrice: {
    display: "none",
    alignSelf: "flex-end",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "block",
    },
  },

  price: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  detailsButton: {
    alignSelf: "flex-end",

    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignSelf: "stretch",
      border: "none",
    },
  },
}));
