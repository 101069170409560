import { RentalStatus } from "types";
import { z } from "zod";
import { invoiceSchema } from "./invoice";
import { enumFrom } from "./utils";

export const rentalCustomerSchema = z.object({
  email: z.string(),
  id: z.string(),
});

export const rentalRetailerInfosSchema = z.object({
  id: z.string(),
  name: z.string(),
  claim_form_url: z.string().nullable(),
  payment_processor_public_api_key: z.string(),
});

export const rentalCoverageConfigurationSchema = z.object({
  code: z.string(),
});

export const rentalItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  duration: z.number(),
  image: z.string().nullable(),
  rent: z
    .boolean()
    .optional()
    .transform(() => true),
  first_instalment_with_tax: z.number(),
  other_instalment_with_tax: z.number(),
  rental_start_date: z.string().nullable(),
  rental_end_date: z.string().nullable(),
  serial_number: z.string().nullable(),
  coverage_configuration: rentalCoverageConfigurationSchema.nullable(),
  return_id: z.string().nullable(),
  session_id: z.string(),
  reference: z.string().nullable(),
});

export const rentalCreditNoteSchema = z.object({
  id: z.string(),
  total_amount_cents: z.number(),
  issuing_date: z.string(),
  file_url: z.string().nullable(),
});

export const rentalSchema = z
  .object({
    id: z.string(),
    status: enumFrom(RentalStatus),
    created_at: z.string(),
    customer: rentalCustomerSchema,
    retailer_infos: rentalRetailerInfosSchema,
    rental_items: z.array(rentalItemSchema),
    invoices: z.array(invoiceSchema),
    reference: z.string(),
    credit_notes: z.array(rentalCreditNoteSchema),
    sessions_ids: z.array(z.string()),
  })
  .passthrough();
