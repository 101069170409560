import { Anchor, Group, Header as MantineHeader, Text } from "@mantine/core";
import { IconHelpCircle, IconLogout } from "@tabler/icons-react";
import { Button } from "components";
import { useAppContext } from "contexts/AppContext";
import { useAuthContext } from "contexts/AuthContext";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import { ReactComponent as SlineLogo } from "resources/icons/sline-logo.svg";
import { TrackingEventCategory } from "services/analytics";
import { RoutePath } from "types";
import { useStyles } from "./Header.styles";

export const HEADER_HEIGHT = 64;

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const { customerId, logOut } = useAuthContext();
  const { classes } = useStyles();
  const { trackClick } = useEventTracking();
  const { pathname } = useLocation();
  const { openHelpRequest, closeClaimCreation } = useAppContext();

  const isRentalPage = !!matchPath({ path: RoutePath.RENTAL }, pathname);
  const isOrderPage = !!matchPath({ path: RoutePath.ORDER }, pathname);

  return (
    <MantineHeader height={HEADER_HEIGHT} withBorder={false}>
      <Group className={classes.header} position="apart">
        <SlineLogo className={classes.logo} data-testid="sline-logo" />

        <Group spacing="sm">
          {(isRentalPage || isOrderPage) && (
            <Button
              color="#fff"
              variant="secondary"
              leftIcon={<IconHelpCircle />}
              aria-label="contactUs"
              onClick={() => {
                closeClaimCreation(openHelpRequest);
                trackClick(TrackingEventCategory.LINK, "click_contact");
              }}
              className={classes.contactUs}
            >
              {t("header.help")}
            </Button>
          )}

          {!!customerId && (
            <Anchor
              onClick={() => {
                logOut();
                trackClick(TrackingEventCategory.LINK, "click_log_out");
              }}
              className={classes.logout}
              data-testid="logout-link"
            >
              <Group spacing="xs">
                <Text span>{t("header.logOut")}</Text>
                <IconLogout />
              </Group>
            </Anchor>
          )}
        </Group>
      </Group>
    </MantineHeader>
  );
};
