import { Anchor, Stack, Text, Title } from "@mantine/core";
import { useRentalsQuery } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutePath } from "types";
import { RentalCard, RentalSkeletonCard } from "./components";

export const RentalsPage: React.FC = () => {
  usePageViewTracking("rentals-list");
  const { t } = useTranslation();
  const { rentals } = useRentalsQuery();

  return (
    <Stack spacing="lg">
      <Title size="h3">{t("rental.myRentals")}</Title>

      {rentals ? (
        rentals.length === 0 ? (
          <Text>
            <Trans
              i18nKey="rental.noRentalsMessage"
              components={{
                a: <Anchor component={Link} to={RoutePath.ORDERS} td="underline" />,
              }}
            />
          </Text>
        ) : (
          rentals.map((rental) => <RentalCard key={rental.id} rental={rental} />)
        )
      ) : (
        <RentalSkeletonCard />
      )}
    </Stack>
  );
};
